import i18n from '@/locale/i18n';

const messages = i18n.messages[i18n.locale];

export default {
  isLoading: false,
  isSubmitting: false,
  currentUser: null,
  users: null,
  metadata: null,
  currentAccessRights: null,
  accessRights: null,
  specsWithGrades: null,
  reports: null,
  tests360: null,
  tests360ForApproval: null,
  notifications: null,
  targets: {
    self: null,
    curator: null,
  },
  curator: {
    adaptation: null,
  },
  employers: null,
  companies: null,
  portfolio: null,
  workExp: null,
  education: null,
  educationLevels: [
    {value: 'top', name: messages.educationLevels.top},
    {value: 'bachelor', name: messages.educationLevels.bachelor},
    {value: 'magister', name: messages.educationLevels.magister},
    {value: 'top_highest', name: messages.educationLevels.topHighest},
    {value: 'candidate', name: messages.educationLevels.candidate},
    {value: 'adjunct', name: messages.educationLevels.adjunct},
    {value: 'aspirant', name: messages.educationLevels.aspirant},
    {value: 'doctor', name: messages.educationLevels.doctor},
    {value: 'aux_kids_adults', name: messages.educationLevels.auxKidsAdults},
    {value: 'aux_prof', name: messages.educationLevels.auxProf},
    {value: 'infants', name: messages.educationLevels.infants},
    {value: 'intern', name: messages.educationLevels.intern},
    {value: 'starter_common', name: messages.educationLevels.starterCommon},
    {value: 'starter_prof', name: messages.educationLevels.starterProf},
    {value: 'unfinished_top', name: messages.educationLevels.unfinishedTop},
    {value: 'ordinate', name: messages.educationLevels.ordinate},
    {value: 'main_common', name: messages.educationLevels.mainCommon},
    {value: 'after_uni', name: messages.educationLevels.afterUni},
    {value: 'prof_training', name: messages.educationLevels.profTraining},
    {value: 'middle_full', name: messages.educationLevels.middleFull},
    {value: 'middle', name: messages.educationLevels.middle},
    {value: 'middle_special', name: messages.educationLevels.middleSpecial},
  ],
  errors: null,
  validationErrors: null,
  validationErrorCode: undefined,
};
