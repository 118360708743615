export const mutationTypes = {
  createTargetAsUserStart: '[target] create target as user start',
  createTargetAsUserSuccess: '[target] create target as user success',
  createTargetAsUserFailure: '[target] create target as user failure',

  createTargetAsCuratorStart: '[target] create target as curator start',
  createTargetAsCuratorSuccess: '[target] create target as curator success',
  createTargetAsCuratorFailure: '[target] create target as curator failure',

  changeTargetStart: '[target] change target start',
  changeTargetSuccess: '[target] change target success',
  changeTargetFailure: '[target] change target failure',

  gradeTargetStart: '[target] grade target start',
  gradeTargetSuccess: '[target] grade target success',
  gradeTargetFailure: '[target] grade target failure',

  moveTargetStart: '[target] move target start',
  moveTargetSuccess: '[target] move target success',
  moveTargetFailure: '[target] move target failure',

  deleteTargetStart: '[target] delete target start',
  deleteTargetSuccess: '[target] delete target success',
  deleteTargetFailure: '[target] delete target failure',

  changeCuratorsStart: '[target] change curators start',
  changeCuratorsSuccess: '[target] change curators success',
  changeCuratorsFailure: '[target] change curators failure',

  getCommentsStart: '[target] get comments start',
  getCommentsSuccess: '[target] get comments success',
  getCommentsFailure: '[target] get comments failure',

  getRightsStart: '[target] get rights start',
  getRightsSuccess: '[target] get rights success',
  getRightsFailure: '[target] get rights failure',
};

const mutations = {
  [mutationTypes.createTargetAsUserStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTargetAsUserSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTargetAsUserFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createTargetAsCuratorStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTargetAsCuratorSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTargetAsCuratorFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeTargetStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTargetSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTargetFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.gradeTargetStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.gradeTargetSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.gradeTargetFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.moveTargetStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.moveTargetSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.moveTargetFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTargetStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTargetSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTargetFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeCuratorsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeCuratorsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeCuratorsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getCommentsStart](state) {
    state.isLoading = true;
    state.comments = null;
  },
  [mutationTypes.getCommentsSuccess](state, payload) {
    state.isLoading = false;
    state.comments = payload;
  },
  [mutationTypes.getCommentsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRightsStart](state) {
    state.isLoading = true;
    state.rights = null;
  },
  [mutationTypes.getRightsSuccess](state, payload) {
    state.isLoading = false;
    state.rights = payload;
  },
  [mutationTypes.getRightsFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
