export const mutationTypes = {
  getSettingsStart: '[navigation] get settings start',
  getSettingsSuccess: '[navigation] get settings success',
  getSettingsFailure: '[navigation] get settings failure',

  getPresenceStart: '[navigation] get presence start',
  getPresenceSuccess: '[navigation] get presence success',
  getPresenceFailure: '[navigation] get presence failure',
};

const mutations = {
  [mutationTypes.getSettingsStart](state) {
    state.isLoading.settings = true;
  },
  [mutationTypes.getSettingsSuccess](state, payload) {
    state.isLoading.settings = false;
    state.settings = payload;
  },
  [mutationTypes.getSettingsFailure](state) {
    state.isLoading.settings = false;
  },

  [mutationTypes.getPresenceStart](state) {
    state.isLoading.presence = true;
  },
  [mutationTypes.getPresenceSuccess](state, payload) {
    state.isLoading.presence = false;
    state.presence = payload;
  },
  [mutationTypes.getPresenceFailure](state) {
    state.isLoading.presence = false;
  },
};

export default mutations;
