import axios from '@/api/axios';

const getIface = () => axios.get('/roadmap/iface');

const getReport = (id, userId) => axios.get(`/roadmap/${userId}/report/${id}`);

const getVersions = (userId, company) =>
  axios.get(`/roadmaps/${userId}${company ? `?company=${company}` : ''}`);

const getVersionsBySpec = (userId, specCode, company) =>
  axios.get(`/roadmaps/${userId}/${specCode}${company ? `?company=${company}` : ''}`);

const getHeader = (id, userId) => axios.get(`/roadmap/${userId}/header/${id}`);

const getCommonMaterials = (id, userId) => axios.get(`/roadmap/${userId}/materials/${id}/common`);

const getSkillsMaterials = (id, userId) => axios.get(`/roadmap/${userId}/materials/${id}/skills`);

const getBlocksMaterials = (id, userId) => axios.get(`/roadmap/${userId}/materials/${id}/blocks`);

const getBlockProgress = (id, userId, blockId) =>
  axios.get(`/roadmap/${userId}/${id}/block/${blockId}/progress`);

const recreateRoadmap = (id, userId, skillType, specId) =>
  axios.put(
    `/roadmap/${id}/${userId}/recreate?skill_type=${skillType}&specialization_id=${specId}`
  );

const fixateRoadmap = (id, userId) => axios.post(`/roadmap/${userId}/header/${id}/fixate`);

const changeHeader = (id, userId, data) => axios.put(`/roadmap/${userId}/header/${id}`, data);

// materials
const changeMaterial = (userId, data) => axios.put(`/roadmap/${userId}/material`, data);

const showMaterials = (id, userId, data) =>
  axios.put(`/roadmap/${userId}/materials/${id}/show`, data);

const deleteMaterials = (id, userId, data) =>
  axios.put(`/roadmap/${userId}/materials/${id}/delete`, data);

const changeBlock = (userId, data) => axios.put(`/roadmap/${userId}/block`, data);

export default {
  getIface,
  getReport,
  getVersions,
  getVersionsBySpec,
  getHeader,
  getCommonMaterials,
  getSkillsMaterials,
  getBlocksMaterials,
  getBlockProgress,

  recreateRoadmap,
  fixateRoadmap,
  changeHeader,

  changeMaterial,
  showMaterials,
  deleteMaterials,

  changeBlock,
};
