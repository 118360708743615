import boardApi from '@/api/board';
import {mutationTypes} from '@/store/modules/board/mutations';

export const actionsTypes = {
  getCards: '[board] get cards',
  getCard: '[board] get card',
  getRoadmaps: '[board] get roadmaps',

  changeShowCards: '[board] change show cards',
};

const actions = {
  [actionsTypes.getCards](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCardsStart);
      boardApi
        .getCards(userId)
        .then((response) => {
          context.commit(mutationTypes.getCardsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCardsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCard](context, {cardId, cardType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCardStart);
      boardApi
        .getCard(cardId, cardType)
        .then((response) => {
          context.commit(mutationTypes.getCardSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCardFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getRoadmaps](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRoadmapsStart);
      boardApi
        .getRoadmaps(userId)
        .then((response) => {
          context.commit(mutationTypes.getRoadmapsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRoadmapsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeShowCards](context, {userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeShowCardsStart);
      boardApi
        .changeShowCards(userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeShowCardsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeShowCardsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
