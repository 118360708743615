class FacebookModule {
  constructor() {
    this.counterId = null;
    this.visitParams = {};
  }
  standartLog() {
    if (this['msg']) {
      console.log(this['msg']);
    }
  }
  initMonitoring(id) {
    this.counterId = id;

    //FB_Pixel init
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };

      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';

      n.queue = [];
      t = b.createElement(e);
      t.async = !0;

      t.src = v;
      s = b.getElementsByTagName(e)[0];

      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',

      'https://connect.facebook.net/en_US/fbevents.js'
    );

    // eslint-disable-next-line no-undef
    fbq('init', id);
    console.log(`Metric initialized: Facebook ${id}`);

    // SEO.initializedMetrics.push('facebook'); //Этим занимается родитель теперь
    return true;
  }

  registerHit() {
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
    return true;
  }

  registerTarget(targetId) {
    // eslint-disable-next-line no-undef
    fbq('track', targetId);
    return true;
  }

  reportVisitParams() {
    return false;
  }

  registerExternalLink() {
    return false;
  }

  assignUser() {
    return false;
  }
}

export default FacebookModule;
