export default {
  skillsTree: null,
  versions: null,
  materialsByQuestion: null,
  materialsBySkill: null,
  materialsGrades: null,
  materialsBySpec: null,
  specializations: null,
  specializationGroups: null,
  baseProfileTree: null,
  questionTooltips: null,
  questionScale: null,
  isLoading: false,
  isLoadingSpec: false,
  isLoadingGroup: false,
  isLoadingTree: false,
  isLoadingQuestionTooltips: false,
  isLoadingVersion: false,
  isSubmitting: false,
  isTest: false,
  errors: null,
  validationErrors: null,
};
