import adminApi from '@/api/administration';
import {mutationTypes} from '@/store/modules/administration/mutations';

export const actionsTypes = {
  getUserList: '[administration] get users',
  toggleUserBan: '[administration] toggle user ban',
  toggleUserImp: '[administration] toggle user impersonate',
  changeUserEmail: '[administration] change user email',
  getCompanyList: '[administration] get companies',
};

const actions = {
  [actionsTypes.getUserList](
    context,
    {search = undefined, company_ids, limit = 50, offset = 0, reload = false}
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserListStart);
      adminApi
        .getUserList(search, company_ids, limit, offset + context.state.offset)
        .then((response) => {
          context.commit(mutationTypes.getUserListSuccess, {...response.data, reload});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserListFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCompanyList](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserListStart);
      adminApi
        .getCompanyList()
        .then((response) => {
          context.commit(mutationTypes.getCompanyListSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserListFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.toggleUserBan](context, {uid, banned}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserListStart);
      adminApi
        .toggleUserBanState(uid, banned)
        .then((response) => {
          context.commit(mutationTypes.toggleBanSuccess, {type: 'ban', uid, banned});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserListFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.toggleUserImp](context, {uid, action}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserListStart);
      adminApi
        .toggleUserImp(uid, action)
        .then((response) => {
          context.commit(mutationTypes.toggleBanSuccess, {type: 'imp', uid, action});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserListFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeUserEmail](context, {uid, new_email}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserListStart);
      adminApi
        .changeUserEmail(uid, new_email)
        .then((response) => {
          context.commit(mutationTypes.toggleBanSuccess, {type: 'email', uid, new_email});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserListFailure);
          reject(result);
        });
    });
  },
};

export default actions;
