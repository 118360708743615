import axios from '@/api/axios';

const getUsers = (fio, page = 1, limit = 10, start = 0) =>
  axios.get(`/user?fio=${fio}&page=${page}&limit=${limit}&start=${start}`);

const getMetadataUser = (id) => axios.get(`/user/${id}`);

const getAccessRights = (companyId) => axios.get(`/user/rights/company/${companyId}`);

const getSpecsWithGrades = (id, teamId) =>
  axios.get(`/user/${id}/specializations_with_grades${teamId ? `?team_id=${teamId}` : ''}`);

const changeMetadataUser = (credentials) => axios.put(`/user/${credentials.id}`, credentials);
const sendPasswordLink = (userId) => axios.post(`/user/${userId}/password`);

const changeAvatar = (credentials) =>
  axios.post(`/user/${credentials.id}/avatar`, credentials.body);

const changeEmail = (credentials) => axios.post('/sendCode', credentials);
const approveEmail = (code) => axios.post('/validateCode', code);

const changeSpecializations = (credentials) =>
  axios.put(`/user/${credentials.id}/specializations`, credentials.body);

// HR information
const changeHRInfo = (id, data) => axios.put(`/user/${id}/hr_info`, data);

const createHRAttachment = (id, data) => axios.post(`/user/${id}/hr_info/attachments`, data);

const deleteHRAttachment = (id, attachId) =>
  axios.delete(`/user/${id}/hr_info/attachments/${attachId}`);

const getEmployers = (id) => axios.get(`/user/${id}/employers`);

const getCompanies = (id) => axios.get(`/user/${id}/company`);

// create company
const createCompany = (id, data) => axios.post(`/user/${id}/company`, data);

const createCompanyLogo = (id, data) => axios.post(`/user/${id}/company/logo`, data);

// portfolio
const getPortfolio = (id) => axios.get(`/user/${id}/portfolio`);

const downloadPortfolio = (id) =>
  axios
    .get(`/user/${id}/portfolio/download`)
    .then((response) => (window.location.href = response.data));

const createPortfolio = (id, data) => axios.post(`/user/${id}/portfolio`, data);

const deletePortfolio = (id, portfolioId) => axios.delete(`/user/${id}/portfolio/${portfolioId}`);

// work experience
const getWorkExp = (id) => axios.get(`/user/${id}/work_exp`);

const createWorkExp = (id, data) => axios.post(`/user/${id}/work_exp`, data);

const changeWorkExp = (id, experienceId, data) =>
  axios.put(`/user/${id}/work_exp/${experienceId}`, data);

const deleteWorkExp = (id, experienceId) => axios.delete(`/user/${id}/work_exp/${experienceId}`);

// education
const getEducation = (id) => axios.get(`/user/${id}/education`);

const changeEducation = (id, data) => axios.put(`/user/${id}/education`, data);

const deleteEducation = (id, data) => axios.delete(`/user/${id}/education`, {data});

// invites
const acceptInvitation = (id, employeeId) => axios.post(`/user/${id}/invites/${employeeId}`);

const rejectInvitation = (id, employeeId) => axios.delete(`/user/${id}/invites/${employeeId}`);

// reports
const getReports = (id, companyId) =>
  axios.get(`/user/${id}/reports${companyId ? `?company=${companyId}` : ''}`);

// tests
const getTests360 = () => axios.get('/user/tests360');

const getTests360ForApproval = () => axios.get('/user/tests360_for_approval');

// notifications
const getNotifications = () => axios.get('/user/notification');

const changeNotification = (notificationId) =>
  axios.put(`/user/notification${notificationId ? `?notification_id=${notificationId}` : ''}`);

const deleteNotification = (notificationId, isRead) =>
  axios.delete(
    `/user/notification${notificationId ? `?notification_id=${notificationId}` : `?read=${isRead}`}`
  );

// targets
const getSelfTargets = () => axios.get('/user/targets/assigned');

const getCuratorTargets = () => axios.get('/user/targets/curated');

const exportCuratorTargets = () =>
  axios.get('/user/targets/curated/export', {
    responseType: 'blob',
  });

// adaptation
const getCuratorAdaptation = () => axios.get('/user/curated/adaptation');

export default {
  getUsers,
  getMetadataUser,
  getAccessRights,
  getSpecsWithGrades,
  changeMetadataUser,
  sendPasswordLink,
  changeAvatar,
  changeEmail,
  approveEmail,
  changeSpecializations,
  changeHRInfo,
  createHRAttachment,
  deleteHRAttachment,
  getEmployers,
  getCompanies,

  createCompany,
  createCompanyLogo,

  getPortfolio,
  downloadPortfolio,
  createPortfolio,
  deletePortfolio,

  getWorkExp,
  createWorkExp,
  changeWorkExp,
  deleteWorkExp,

  getEducation,
  changeEducation,
  deleteEducation,

  getReports,

  getTests360,
  getTests360ForApproval,

  acceptInvitation,
  rejectInvitation,

  getNotifications,
  changeNotification,
  deleteNotification,

  getSelfTargets,
  getCuratorTargets,
  exportCuratorTargets,

  getCuratorAdaptation,
};
