import axios from 'axios';

const getSettings = (companyId) => axios.get(`/navigation/company/${companyId}`);

const getPresence = () => axios.get('/navigation/presence');

export default {
  getSettings,
  getPresence,
};
