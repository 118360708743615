import Vue from 'vue';
import VueECharts from 'vue-echarts';

/**
 * Vue ECharts
 * https://github.com/ecomfe/vue-echarts
 *
 */
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/polar';
import {BarChart} from 'echarts/charts';
import {
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
  GridComponent,
} from 'echarts/components';
import {use} from 'echarts/core';
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers';
use([
  BarChart,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
  GridComponent,
]);

Vue.component('VChart', VueECharts);
