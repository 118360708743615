import axios from 'axios';

const getMeetings = (userId, role) => {
  let params = [];
  let query = '';

  if (userId) params.push(`user_id=${userId}`);
  if (role) params.push(`role=${role}`);

  if (params.length) query = `?${params.join('&')}`;

  return axios.get(`/meeting/user${query}`);
};

const getMeeting = (meetingId) => axios.get(`/meeting/${meetingId}`);

const getQuestions = (meetingId) => axios.get(`/meeting/${meetingId}/questions`);

const getQuestionsDirectory = () => axios.get('/meeting/questions_directory');

const getComments = (meetingId) => axios.get(`/meeting/${meetingId}/comments`);

const getTasks = (meetingId) => axios.get(`/meeting/${meetingId}/task`);

const createMeeting = (data) => axios.post('/meeting', data);

const changeMeeting = (meetingId, data) => axios.put(`/meeting/${meetingId}`, data);

const rescheduleMeeting = (meetingId, data) => axios.post(`/meeting/${meetingId}/reschedule`, data);

const doneMeeting = (meetingId, data) => axios.post(`/meeting/${meetingId}/done`, data);

const cancelMeeting = (meetingId) => axios.post(`/meeting/${meetingId}/cancelled`);

const addQuestions = (meetingId, data) => axios.post(`/meeting/${meetingId}/questions`, data);

const addComment = (meetingId, data) => axios.post(`/meeting/${meetingId}/comments`, data);

const createTask = (meetingId, data) => axios.post(`/meeting/${meetingId}/task`, data);

const changeTask = (meetingId, taskId, data) =>
  axios.put(`/meeting/${meetingId}/task/${taskId}`, data);

const changeShowTask = (meetingId, taskId, data) =>
  axios.put(`/meeting/${meetingId}/task/${taskId}/show`, data);

const moveTask = (meetingId, taskId, data) =>
  axios.post(`/meeting/${meetingId}/task/${taskId}/move`, data);

const deleteTask = (meetingId, taskId) => axios.delete(`/meeting/${meetingId}/task/${taskId}`);

export default {
  getMeetings,
  getMeeting,
  getQuestions,
  getQuestionsDirectory,
  getComments,
  getTasks,

  createMeeting,
  changeMeeting,
  rescheduleMeeting,
  doneMeeting,
  cancelMeeting,

  addQuestions,
  addComment,
  createTask,
  changeTask,
  changeShowTask,
  moveTask,
  deleteTask,
};
