export const mutationTypes = {
  getSpecializationsStart: '[directory] get specializations start',
  getSpecializationsSuccess: '[directory] get specializations success',
  getSpecializationsFailure: '[directory] get specializations failure',

  getReallyAllSpecializationsStart: '[directory] get really all specializations start',
  getReallyAllSpecializationsSuccess: '[directory] get really all specializations success',
  getReallyAllSpecializationsFailure: '[directory] get really all specializations failure',

  getSpecializationStart: '[directory] get specialization start',
  getSpecializationSuccess: '[directory] get specialization success',
  getSpecializationFailure: '[directory] get specialization failure',

  getAddressesStart: '[directory] get addresses start',
  getAddressesSuccess: '[directory] get addresses success',
  getAddressesFailure: '[directory] get addresses failure',

  getOrganizationsStart: '[directory] get organizations start',
  getOrganizationsSuccess: '[directory] get organizations success',
  getOrganizationsFailure: '[directory] get organizations failure',

  getServicesStart: '[directory] get services start',
  getServicesSuccess: '[directory] get services success',
  getServicesFailure: '[directory] get services failure',

  getSkillsStart: '[directory] get skills start',
  getSkillsSuccess: '[directory] get skills success',
  getSkillsFailure: '[directory] get skills failure',

  getTestingIndicatorsStart: '[directory] get testing indicators start',
  getTestingIndicatorsSuccess: '[directory] get testing indicators success',
  getTestingIndicatorsFailure: '[directory] get testing indicators failure',

  getMaterialTypesStart: '[directory] get material types start',
  getMaterialTypesSuccess: '[directory] get material types success',
  getMaterialTypesFailure: '[directory] get material types failure',

  getScalesStart: '[directory] get scales start',
  getScalesSuccess: '[directory] get scales success',
  getScalesFailure: '[directory] get scales failure',

  getTargetTypesStart: '[directory] get target types start',
  getTargetTypesSuccess: '[directory] get target types success',
  getTargetTypesFailure: '[directory] get target types failure',
};

const mutations = {
  [mutationTypes.getSpecializationsStart](state) {
    state.isLoading = true;
    state.specializations = null;
  },
  [mutationTypes.getSpecializationsSuccess](state, payload) {
    state.isLoading = false;
    state.specializations = payload;
  },
  [mutationTypes.getSpecializationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getReallyAllSpecializationsStart](state) {
    state.isLoading = true;
    state.reallyAllSpecializations = null;
  },
  [mutationTypes.getReallyAllSpecializationsSuccess](state, payload) {
    state.isLoading = false;
    state.reallyAllSpecializations = payload;
  },
  [mutationTypes.getReallyAllSpecializationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecializationStart](state) {
    state.isLoading = true;
    state.specialization = null;
  },
  [mutationTypes.getSpecializationSuccess](state, payload) {
    state.isLoading = false;
    state.specialization = payload;
  },
  [mutationTypes.getSpecializationFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAddressesStart](state) {
    state.isLoading = true;
    state.addresses = null;
  },
  [mutationTypes.getAddressesSuccess](state, payload) {
    state.isLoading = false;
    state.addresses = payload;
  },
  [mutationTypes.getAddressesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getOrganizationsStart](state) {
    state.isLoading = true;
    state.organizations = null;
  },
  [mutationTypes.getOrganizationsSuccess](state, payload) {
    state.isLoading = false;
    state.organizations = payload;
  },
  [mutationTypes.getOrganizationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getServicesStart](state) {
    state.isLoading = true;
    state.services = null;
  },
  [mutationTypes.getServicesSuccess](state, payload) {
    state.isLoading = false;
    state.services = payload;
  },
  [mutationTypes.getServicesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSkillsStart](state) {
    state.isLoading = true;
    state.skills = null;
  },
  [mutationTypes.getSkillsSuccess](state, payload) {
    state.isLoading = false;
    state.skills = payload;
  },
  [mutationTypes.getSkillsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTestingIndicatorsStart](state) {
    state.isLoading = true;
    state.testingIndicators = null;
  },
  [mutationTypes.getTestingIndicatorsSuccess](state, payload) {
    state.isLoading = false;
    state.testingIndicators = payload;
  },
  [mutationTypes.getTestingIndicatorsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMaterialTypesStart](state) {
    state.isLoading = true;
    state.materialTypes = null;
  },
  [mutationTypes.getMaterialTypesSuccess](state, payload) {
    state.isLoading = false;
    state.materialTypes = payload;
  },
  [mutationTypes.getMaterialTypesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getScalesStart](state) {
    state.isLoading = true;
    state.scales = null;
  },
  [mutationTypes.getScalesSuccess](state, payload) {
    state.isLoading = false;
    state.scales = payload;
  },
  [mutationTypes.getScalesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTargetTypesStart](state) {
    state.isLoading = true;
    state.targetTypes = null;
  },
  [mutationTypes.getTargetTypesSuccess](state, payload) {
    state.isLoading = false;
    state.targetTypes = payload;
  },
  [mutationTypes.getTargetTypesFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
