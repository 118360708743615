import {sortBy} from '@/helpers/utils';

export const gettersTypes = {
  currentLength: '[survey] current feed items count',
  questions: '[survey] current survey questions',
};

const getters = {
  [gettersTypes.currentLength]: (state) => {
    if (!state.feed || !state.feed.left.length) return undefined;
    return state.feed.length;
  },
  [gettersTypes.questions]: (state) => {
    if (!state.survey || !state.survey.survey_meta || !state.survey.survey_meta.length) return [];
    return sortBy(state.survey.survey_meta, 'index', true);
  },
};

export default getters;
