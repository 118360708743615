export default {
  settings: null,
  presence: null,
  isLoading: {
    settings: false,
    presence: false,
  },
  errors: null,
  validationErrors: null,
};
