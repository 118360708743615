import {connect} from 'vuex-connect';
import AppRedactorX from './RedactorX.vue';
import {actionsTypes as utilsActionsTypes} from '@/store/modules/utils/actions';

export default connect({
  stateToProps: {
    isFileUploading: (state) => state.utils.isFileUploading,
  },

  methodsToEvents: {
    'upload-file': async function ({dispatch}, {files, successCallback, errorCallback}) {
      dispatch(utilsActionsTypes.uploadFile, {
        files,
      })
        .then((response) => successCallback(response))
        .catch(() => errorCallback());
    },
  },

  lifecycle: {
    mounted: async function () {},
  },
})('AppRedactorX', AppRedactorX);
