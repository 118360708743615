export const gettersTypes = {
  countFailureTasks: '[external] count failure tasks',
  countSuccessTasks: '[external] count success tasks',
};

const getters = {
  [gettersTypes.countFailureTasks]: (state) => {
    if (!state.statuses) return 0;

    let count = 0;
    for (let status of Object.values(state.statuses)) {
      if (status === 'FAILURE') count++;
    }

    return count;
  },
  [gettersTypes.countSuccessTasks]: (state) => {
    if (!state.statuses) return 0;

    let count = 0;
    for (let status of Object.values(state.statuses)) {
      if (status === 'SUCCESS') count++;
    }

    return count;
  },
};

export default getters;
