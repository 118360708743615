RedactorX.add('plugin', 'removeformat', {
  translations: {en: {removeformat: {removeformat: 'Remove Format'}}},
  defaults: {
    icon: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m8 11c.51283584 0 .93550716.3860402.99327227.8833789l.00672773.1166211v1c0 .5522847-.44771525 1-1 1-.51283584 0-.93550716-.3860402-.99327227-.8833789l-.00672773-.1166211v-1c0-.5522847.44771525-1 1-1zm-4.60436072-5.91850958.10436072.05248418 9.5262794 5.5c.4782927.2761424.6421678.8877328.3660254 1.3660254-.2564179.4441289-.8020741.6171529-1.2616646.4185096l-.1043608-.0524842-9.5262794-5.5c-.47829262-.27614237-.64216778-.88773278-.3660254-1.3660254.25641792-.44412886.80207419-.61715287 1.26166468-.41850958zm9.60436072-3.08149042c.5522847 0 1 .44771525 1 1 0 .51283584-.3860402.93550716-.8833789.99327227l-.1166211.00672773h-4.00016698l.00016698 2c0 .55228475-.44771525 1-1 1-.51283584 0-.93550716-.38604019-.99327227-.88337887l-.00672773-.11662113-.00016698-2h-3.99983302c-.55228475 0-1-.44771525-1-1 0-.51283584.38604019-.93550716.88337887-.99327227l.11662113-.00672773z"/></svg>',
  },
  start: function () {
    var o = {
      title: '## removeformat.removeformat ##',
      icon: this.opts.removeformat.icon,
      command: 'inline.removeFormat',
      position: {after: ['deleted', 'italic']},
      blocks: {all: 'editable'},
    };
    this.app.toolbar.add('removeformat', o), this.app.context.add('removeformat', o);
  },
});
