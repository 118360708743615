export const gettersTypes = {
  tags: '[utils] tags',
};

const getters = {
  [gettersTypes.tags]: (state) => {
    return (code) => {
      return state.boundTags[code] || [];
    };
  },
};

export default getters;
