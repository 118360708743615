import axios from 'axios';

const getPlans = (companyId) => axios.get(`/adaptation/${companyId}/plans`);

const getAssignedAdaptations = (companyId) => axios.get(`/adaptation/${companyId}/adaptation`);

const getUserAdaptation = (companyId, adaptationId) =>
  axios.get(`/adaptation/${companyId}/${adaptationId}`);

const assignAdaptation = (companyId, data) => axios.post(`/adaptation/assign/${companyId}`, data);

const changeDeadlineAdaptation = (companyId, adaptationId, deadline) =>
  axios.post(`/adaptation/${companyId}/${adaptationId}${deadline ? `?date_end=${deadline}` : ''}`);

const createPlan = (companyId, data) => axios.post(`/adaptation/${companyId}/plans`, data);

const editPlan = (companyId, planId, data) =>
  axios.put(`/adaptation/${companyId}/plans/${planId}`, data);

const deletePlan = (companyId, planId) => axios.delete(`/adaptation/${companyId}/plans/${planId}`);

const exportPlan = (companyId, planId, format) =>
  axios.get(`/adaptation/${companyId}/plans/${planId}/export?format_=${format}`, {
    responseType: 'blob',
  });

// tasks
const getTasks = (companyId, planId) => axios.get(`/adaptation/${companyId}/plans/${planId}/tasks`);

const createTask = (companyId, planId, data) =>
  axios.post(`/adaptation/${companyId}/plans/${planId}/tasks`, data);

const editTask = (companyId, planId, taskId, data) =>
  axios.post(`/adaptation/${companyId}/plans/${planId}/tasks/${taskId}`, data);

const swapTasks = (companyId, planId, data) =>
  axios.post(`/adaptation/${companyId}/plans/${planId}/tasks/swap`, data);

const deleteTaskImage = (companyId, taskId) =>
  axios.put(`/adaptation/${companyId}/tasks/${taskId}/image`);

const deleteTaskFile = (companyId, taskId) =>
  axios.put(`/adaptation/${companyId}/tasks/${taskId}/file`);

const deleteTask = (companyId, planId, taskId) =>
  axios.delete(`/adaptation/${companyId}/plans/${planId}/tasks/${taskId}`);

const getAdaptations = (userId) =>
  axios.get(`/adaptation/user/adaptations${userId ? `?user_id=${userId}` : ''}`);

const getAdaptationUserTasks = (adaptationId, companyId, userId) =>
  axios.get(
    `/adaptation/${adaptationId}/company/${companyId}/user/tasks${
      userId ? `?user_id=${userId}` : ''
    }`
  );

const changeTaskStatus = (adaptationId, taskId) =>
  axios.post(`/adaptation/tasks/${adaptationId}/${taskId}/complete`);

export default {
  getPlans,
  getAssignedAdaptations,
  getUserAdaptation,
  assignAdaptation,
  changeDeadlineAdaptation,
  createPlan,
  editPlan,
  deletePlan,
  exportPlan,

  getTasks,
  createTask,
  editTask,
  swapTasks,
  deleteTaskImage,
  deleteTaskFile,
  deleteTask,

  getAdaptations,
  getAdaptationUserTasks,
  changeTaskStatus,
};
