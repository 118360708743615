import reportsApi from '@/api/reports';
import {mutationTypes} from '@/store/modules/reports/mutations';

export const actionsTypes = {
  getReport360: '[reports] get report 360',
  getReportPersonal: '[reports] get report personal',
  getReportSelf: '[reports] get report self',
  getReportSelfVersions: '[reports] get report versions',
  getReportTeam: '[reports] get report team',
  getReportTeamSpecs: '[reports] get report team specs',
  getReportParams: '[reports] get report params',

  getReport360Link: '[reports] get report 360 link',
  getReportPersonalLink: '[reports] get report personal link',
  getReportSelfLink: '[reports] get report self link',
  getReportTeamLink: '[reports] get report team link',

  setReportParams: '[reports] report params start',
  uploadDiagram: '[reports] upload diagram',
};

const actions = {
  [actionsTypes.getReport360](context, {reportName, userId, testId, teamId, format}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReport360Start);
      reportsApi
        .getReport360(reportName, userId, testId, teamId, format)
        .then((response) => {
          context.commit(mutationTypes.getReport360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReport360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportPersonal](context, {reportName, userId, teamId, format}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportPersonalStart);
      reportsApi
        .getReportPersonal(reportName, userId, teamId, format)
        .then((response) => {
          context.commit(mutationTypes.getReportPersonalSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportPersonalFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportSelf](context, {reportName, userId, specCode, teamId, format, version}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportSelfStart);
      reportsApi
        .getReportSelf(reportName, userId, specCode, teamId, format, version)
        .then((response) => {
          context.commit(mutationTypes.getReportSelfSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportSelfFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportSelfVersions](context, {userId, specCode, skillType, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportSelfVersionsStart);
      reportsApi
        .getReportSelfVersions(userId, specCode, skillType, teamId)
        .then((response) => {
          context.commit(mutationTypes.getReportSelfVersionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportSelfVersionsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportTeam](context, {reportName, teamId, teamIds, specCode, format}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportTeamStart);
      reportsApi
        .getReportTeam(reportName, teamId, teamIds, specCode, format)
        .then((response) => {
          context.commit(mutationTypes.getReportTeamSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportTeamFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportTeamSpecs](context, {skillType, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportTeamSpecsStart);
      reportsApi
        .getReportTeamSpecs(skillType, teamId)
        .then((response) => {
          context.commit(mutationTypes.getReportTeamSpecsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportTeamSpecsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportParams](context, {reportName, targetId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportParamsStart);
      reportsApi
        .getReportParams(reportName, targetId)
        .then((response) => {
          context.commit(mutationTypes.getReportParamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportParamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getReport360Link](context, {reportName, userId, testId, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportLinkStart);
      reportsApi
        .getReport360(reportName, userId, testId, teamId, 'share')
        .then((response) => {
          context.commit(mutationTypes.getReportLinkSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportPersonalLink](context, {reportName, userId, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportLinkStart);
      reportsApi
        .getReportPersonal(reportName, userId, teamId, 'share')
        .then((response) => {
          context.commit(mutationTypes.getReportLinkSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportSelfLink](context, {reportName, userId, specCode, teamId, version}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportLinkStart);
      reportsApi
        .getReportSelf(reportName, userId, specCode, teamId, 'share', version)
        .then((response) => {
          context.commit(mutationTypes.getReportLinkSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReportTeamLink](context, {reportName, specCode, teamId, teamIds}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportLinkStart);
      reportsApi
        .getReportTeam(reportName, teamId, teamIds, specCode, 'share')
        .then((response) => {
          context.commit(mutationTypes.getReportLinkSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.setReportParams](context, {reportName, targetId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setReportParamsStart);
      reportsApi
        .setReportParams(reportName, targetId, data)
        .then(() => {
          context.commit(mutationTypes.setReportParamsSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.setReportParamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.uploadDiagram](context, {reportName, targetId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.uploadDiagramStart);
      reportsApi
        .uploadDiagram(reportName, targetId, data)
        .then((response) => {
          context.commit(mutationTypes.uploadDiagramSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.uploadDiagramFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
