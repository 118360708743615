<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <v-expansion-panels
    v-model="accordion"
    accordion
    readonly
    class="v-expansion-panels--readonly"
    :class="classes"
  >
    <v-expansion-panel>
      <v-expansion-panel-header hide-actions class="d-block">
        <template #default="{open}">
          <v-row align="center">
            <v-col cols="auto" class="ps-0">
              <v-badge
                color="warning"
                overlap
                :value="countActiveFilters"
                :content="countActiveFilters"
              >
                <v-btn
                  text
                  block
                  color="blue"
                  :small="$vuetify.breakpoint.mobile"
                  @click="toggleOpenAccordion"
                >
                  <v-icon left color="blue">mdi-tune</v-icon>
                  <span>{{ $t('buttons.editFilter') }}</span>
                </v-btn>
              </v-badge>
            </v-col>
            <v-col v-if="countActiveFilters" cols="auto">
              <v-btn
                :small="$vuetify.breakpoint.mobile"
                text
                color="blue"
                block
                @click="onResetFilters"
              >
                <span>{{ $t('buttons.clearFilter') }}</span>
              </v-btn>
            </v-col>
            <slot name="action-button"></slot>
          </v-row>
          <slot v-if="!open && countActiveFilters" name="results"></slot>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider class="mb-4"></v-divider>
        <slot name="filters"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AppExpansionFiltersBar',
  props: {
    countActiveFilters: {
      required: false,
      type: Number,
      default: 0,
    },
    classes: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      accordion: undefined,
    };
  },
  computed: {},
  methods: {
    toggleOpenAccordion() {
      this.accordion = this.accordion !== undefined ? undefined : 0;
    },
    onResetFilters() {
      this.$emit('reset-filters');
    },
  },
};
</script>
