export const gettersTypes = {
  currentVersion: '[admin] current version',
  specializations: '[admin] specializations',
};

const getters = {
  [gettersTypes.currentVersion]: (state) => {
    if (!state.versions) return null;
    return state.versions.current_version;
  },
  [gettersTypes.specializations]: (state) => {
    if (!state.specializations) return null;
    return state.specializations;
  },
};

export default getters;
