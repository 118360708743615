<template>
  <v-menu
    v-model="dateMenu"
    class="app-date-picker-dual"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{on}">
      <v-text-field
        ref="dateRange"
        class="w-100"
        :value="dateRangeDisplay"
        readonly
        outlined
        dense
        :disabled="disabled"
        :label="label"
        :hint="hint"
        :placeholder="placeholder"
        :rules="rules"
        :validate-on-blur="false"
        clearable
        hide-details
        append-icon="mdi-chevron-down"
        @click:append="dateMenu = !dateMenu"
        v-on="on"
        @input="checkCleared"
        @click:clear="date = ''"
      />
    </template>

    <v-date-picker
      v-model="date"
      :min="allowPastDates ? '' : new Date().toISOString()"
      :max="allowFutureDates ? '' : new Date().toISOString()"
      :first-day-of-week="1"
      :disabled="disabled"
      no-title
      scrollable
      color="primary"
      actions
      range
      type="month"
      @input="pickerInput"
    >
      <v-spacer></v-spacer>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppMonthPickerDual',

  props: {
    rules: {
      type: Array,
      default: undefined,
    },

    value: {
      type: Object,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: undefined,
    },

    hint: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    allowPastDates: {
      type: Boolean,
      default: true,
    },

    allowFutureDates: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dateMenu: undefined,
      dateModel: {
        dateStart: undefined,
        dateEnd: undefined,
      },
    };
  },

  computed: {
    dateRangeDisplay() {
      switch (this.date.length) {
        case 0:
          return '';
        case 1:
          return `${moment(this.dateStart).format('MM.YYYY')}`;
        case 2: {
          const s = moment(this.dateStart).format('MM.YYYY');
          const e = moment(this.dateEnd).format('MM.YYYY');
          return `${s} - ${e}`;
        }
      }
      return '';
    },

    dateStart: {
      get() {
        return this.dateModel.dateStart;
      },
      set(value) {
        this.dateModel.dateStart = value;
      },
    },

    dateEnd: {
      get() {
        return this.dateModel.dateEnd;
      },
      set(value) {
        this.dateModel.dateEnd = value;
      },
    },

    date: {
      get() {
        const res = [];
        this.dateStart && res.push(moment(this.dateStart).format('YYYY-MM'));
        this.dateEnd && res.push(moment(this.dateEnd).format('YYYY-MM'));
        return res;
      },

      set(val) {
        switch (val.length) {
          case 0: {
            this.dateStart = undefined;
            this.dateEnd = undefined;
            this.emitInterval();
            break;
          }
          case 1: {
            this.dateStart = val[0];
            this.dateEnd = undefined;
            break;
          }
          case 2: {
            this.dateStart = val[0];
            this.dateEnd = val[1];
            this.emitInterval();
            break;
          }
        }
      },
    },
  },

  watch: {
    value(val) {
      if (!val || (!val.dateEnd && !val.dateStart)) this.init();
    },

    dateMenu(val) {
      if (!val) {
        this.handleClose();
      }
    },
  },

  mounted() {
    this.init(this.value);
  },

  methods: {
    emitInterval() {
      const {dateStart, dateEnd} = this;
      if (!dateStart && !dateEnd) {
        this.$emit('input', this.dateModel);
      } else {
        const de = dateEnd.split('-');
        this.$emit('input', {
          dateStart: dateStart + '-01',
          dateEnd: moment(new Date(de[0], Number(de[1]), 0)).format('YYYY-MM-DD'),
        });
      }
      this.dateMenu = false;
    },

    handleClose() {
      const {dateStart, dateEnd} = this;
      if (!dateStart && !dateEnd) return;
      if (dateStart && dateEnd) return;

      if (!dateEnd) {
        const now = new Date();
        const ds = new Date(dateStart);

        if (now < ds) {
          this.dateEnd = dateStart;
          this.dateStart = moment(now).format('YYYY-MM');
        } else {
          this.dateEnd = moment(now).format('YYYY-MM');
        }
      }
      this.emitInterval();
    },

    checkCleared(val) {
      if (!val) {
        this.date = [undefined, undefined];
      }
    },

    pickerInput(newDate) {
      if (newDate.length === 2) {
        if (new Date(newDate[0]) > new Date(newDate[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      }
    },

    init(val) {
      if (val) {
        this.dateModel = {
          dateStart: (val.dateStart || '').substring(0, 7),
          dateEnd: (val.dateEnd || '').substring(0, 7),
        };
      } else {
        this.dateModel = {
          dateStart: undefined,
          dateEnd: undefined,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-date-picker-dual {
  z-index: 100;
}
.v-text-field {
  &.theme--light {
    & ::placeholder {
      color: get($shades, 'black');
    }
    & ::-moz-placeholder {
      color: get($shades, 'black');
    }
    & :-ms-input-placeholder {
      color: get($shades, 'black');
    }
  }
  &.theme--dark {
    & ::placeholder {
      color: get($shades, 'white');
    }
    & ::-moz-placeholder {
      color: get($shades, 'white');
    }
    & :-ms-input-placeholder {
      color: get($shades, 'white');
    }
  }
}
</style>
