import Vue from 'vue';

export const mutationTypes = {
  getUserListStart: '[administration] get users start',
  getUserListSuccess: '[administration] get users success',
  getUserListFailure: '[administration] get users failure',
  getCompanyListSuccess: '[administration] get company success',
  toggleBanSuccess: '[administration] toggle ban',
  clearOffset: '[administration] clear offset',
};

const mutations = {
  [mutationTypes.getUserListStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserListSuccess](state, payload) {
    state.isLoading = false;
    if (payload.reload) {
      state.users = [...payload.items];
    } else {
      state.users.push(...payload.items);
    }
    state.count = payload.count;
    state.offset = payload.offset;
  },
  [mutationTypes.getUserListFailure](state) {
    state.isLoading = false;
  },
  [mutationTypes.toggleBanSuccess](state, {type, banned, uid, action, new_email}) {
    state.isLoading = false;
    let users = [...state.users];
    let idx = users.findIndex((user) => user.uid === uid);
    if (idx >= 0) {
      let usr = state.users[idx];
      if (type === 'email') {
        usr.email = new_email;
      } else if (type === 'ban') {
        usr.banned = banned;
      } else if (type === 'imp') {
        usr.has_imp_role = action === 'grant';
      }
      users.splice(idx, 1, usr);
      Vue.set(state, 'users', users);
    }
  },
  [mutationTypes.clearOffset](state) {
    state.count = 0;
    state.offset = 0;
  },
  [mutationTypes.getCompanyListSuccess](state, payload) {
    state.isLoading = false;
    state.companyList = payload;
  },
};

export default mutations;
