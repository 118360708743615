export default {
  methodologies: null,
  methodology: null,
  feedbacks: null,
  userFeedbacks: null,
  feedback: null,
  statistics: null,
  results: null,
  cycles: [],
  isLoading: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
