const defaultTheme = {
  light: {
    primary: '#FFDE6B',
    'primary-contrast': '#222529',
    secondary: '#222529',
    info: '#F3F6F9',
    'info-accent': '#DCE4F5',
    text: '#C09D07',
    yellow: '#FFDE6B',
    'yellow-accent-6': '#FFD466',
    'yellow-accent-7': '#8C6500',
    warning: '#FF764C',
    error: '#FF764C',
    'red-lighten-6': '#FF9A7B',
    success: '#48CD9D',
    'green-lighten-6': '#A3E6CE',
    shades: '#000000',
    'shades-inverse': '#ffffff',
    outline: '#D6D6D6',
    grey: '#9E9E9E',
    'grey-lighten-6': '#DBDEE0',
    blue: '#5987E2',
  },
  dark: {
    primary: '#FFDE6B',
    'primary-contrast': '#222529',
    secondary: '#222529',
    info: '#222529',
    'info-accent': '#222529',
    text: '#FFDE6B',
    yellow: '#FFDE6B',
    'yellow-accent-6': '#FFD466',
    'yellow-accent-7': '#8C6500',
    warning: '#FF764C',
    error: '#FF764C',
    'red-lighten-6': '#FF9A7B',
    success: '#48CD9D',
    'green-lighten-6': '#A3E6CE',
    shades: '#FFFFFF',
    'shades-inverse': '#000000',
    outline: '#424242',
    grey: '#9E9E9E',
    'grey-lighten-6': '#DBDEE0',
    blue: '#5987E2',
  },
};

export default defaultTheme;
