export default {
  meetingsParticipant: null,
  meetingsSupervisor: null,
  meeting: null,
  questions: null,
  questionsDirectory: null,
  comments: null,
  tasks: null,
  isLoading: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
