export const mutationTypes = {
  getIfaceStart: '[roadmap] get iface start',
  getIfaceSuccess: '[roadmap] get iface success',
  getIfaceFailure: '[roadmap] get iface failure',

  getVersionsStart: '[roadmap] get versions start',
  getVersionsSuccess: '[roadmap] get versions success',
  getVersionsFailure: '[roadmap] get versions failure',

  getVersionsBySpecStart: '[roadmap] get versions by spec start',
  getVersionsBySpecSuccess: '[roadmap] get versions by spec success',
  getVersionsBySpecFailure: '[roadmap] get versions by spec failure',

  getHeaderStart: '[roadmap] get header start',
  getHeaderSuccess: '[roadmap] get header success',
  getHeaderFailure: '[roadmap] get header failure',

  getCommonMaterialsStart: '[roadmap] get common materials start',
  getCommonMaterialsSuccess: '[roadmap] get common materials success',
  getCommonMaterialsFailure: '[roadmap] get common materials failure',

  getSkillsMaterialsStart: '[roadmap] get skills materials start',
  getSkillsMaterialsSuccess: '[roadmap] get skills materials success',
  getSkillsMaterialsFailure: '[roadmap] get skills materials failure',

  getBlocksMaterialsStart: '[roadmap] get blocks materials start',
  getBlocksMaterialsSuccess: '[roadmap] get blocks materials success',
  getBlocksMaterialsFailure: '[roadmap] get blocks materials failure',

  recreateRoadmapStart: '[roadmap] recreate roadmap start',
  recreateRoadmapSuccess: '[roadmap] recreate roadmap success',
  recreateRoadmapFailure: '[roadmap] recreate roadmap failure',

  fixateRoadmapStart: '[roadmap] fixate roadmap start',
  fixateRoadmapSuccess: '[roadmap] fixate roadmap success',
  fixateRoadmapFailure: '[roadmap] fixate roadmap failure',

  changeHeaderStart: '[roadmap] change header start',
  changeHeaderSuccess: '[roadmap] change header success',
  changeHeaderFailure: '[roadmap] change header failure',

  changeMaterialStart: '[roadmap] change material start',
  changeMaterialSuccess: '[roadmap] change material success',
  changeMaterialFailure: '[roadmap] change material failure',

  showMaterialsStart: '[roadmap] show materials start',
  showMaterialsSuccess: '[roadmap] show materials success',
  showMaterialsFailure: '[roadmap] show materials failure',

  deleteMaterialsStart: '[roadmap] delete materials start',
  deleteMaterialsSuccess: '[roadmap] delete materials success',
  deleteMaterialsFailure: '[roadmap] delete materials failure',

  changeBlockStart: '[roadmap] change block start',
  changeBlockSuccess: '[roadmap] change block success',
  changeBlockFailure: '[roadmap] change block failure',
};

const mutations = {
  [mutationTypes.getIfaceStart](state) {
    state.isLoading = true;
    state.iface = null;
  },
  [mutationTypes.getIfaceSuccess](state, payload) {
    state.isLoading = false;
    state.iface = payload;
  },
  [mutationTypes.getIfaceFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getVersionsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getVersionsSuccess](state, payload) {
    state.isLoading = false;
    state.versions = payload;
  },
  [mutationTypes.getVersionsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getVersionsBySpecStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getVersionsBySpecSuccess](state, payload) {
    state.isLoading = false;
    state.versionsBySpec = payload;
  },
  [mutationTypes.getVersionsBySpecFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getHeaderStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getHeaderSuccess](state, payload) {
    state.isLoading = false;
    state.header = payload;
  },
  [mutationTypes.getHeaderFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getCommonMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getCommonMaterialsSuccess](state, payload) {
    state.isLoading = false;
    state.commonMaterials = payload;
  },
  [mutationTypes.getCommonMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getSkillsMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getSkillsMaterialsSuccess](state, payload) {
    state.isLoading = false;
    state.skillsMaterials = payload;
  },
  [mutationTypes.getSkillsMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getBlocksMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getBlocksMaterialsSuccess](state, payload) {
    state.isLoading = false;
    state.blocksMaterials = payload;
  },
  [mutationTypes.getBlocksMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.recreateRoadmapStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.recreateRoadmapSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.recreateRoadmapFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.fixateRoadmapStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.fixateRoadmapSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.fixateRoadmapFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeHeaderStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeHeaderSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeHeaderFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMaterialStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMaterialSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMaterialFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.showMaterialsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.showMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.showMaterialsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMaterialsStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMaterialsFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.changeBlockStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeBlockSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeBlockFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
