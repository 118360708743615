export const mutationTypes = {
  getSkillsTreeStart: '[admin] get skills tree start',
  getSkillsTreeSuccess: '[admin] get skills tree success',
  getSkillsTreeFailure: '[admin] get skills tree failure',

  getSkillsVersionsStart: '[admin] get skills versions start',
  getSkillsVersionsSuccess: '[admin] get skills versions success',
  getSkillsVersionsFailure: '[admin] get skills versions failure',

  fixateSkillsVersionStart: '[admin] fixate skills version start',
  fixateSkillsVersionSuccess: '[admin] fixate skills version success',
  fixateSkillsVersionFailure: '[admin] fixate skills version failure',

  registryMoveStart: '[admin] registry move start',
  registryMoveSuccess: '[admin] registry move success',
  registryMoveFailure: '[admin] registry move failure',

  registryEditStart: '[admin] registry edit start',
  registryEditSuccess: '[admin] registry edit success',
  registryEditFailure: '[admin] registry edit failure',

  getMaterialsByQuestionStart: '[admin] get materials by question start',
  getMaterialsByQuestionSuccess: '[admin] get materials by question success',
  getMaterialsByQuestionFailure: '[admin] get materials by question failure',

  createMaterialsByQuestionStart: '[admin] create materials by question start',
  createMaterialsByQuestionSuccess: '[admin] create materials by question success',
  createMaterialsByQuestionFailure: '[admin] create materials by question failure',

  deleteMaterialsByQuestionStart: '[admin] delete materials by question start',
  deleteMaterialsByQuestionSuccess: '[admin] delete materials by question success',
  deleteMaterialsByQuestionFailure: '[admin] delete materials by question failure',

  getMaterialsBySkillStart: '[admin] get materials by skill start',
  getMaterialsBySkillSuccess: '[admin] get materials by skill success',
  getMaterialsBySkillFailure: '[admin] get materials by skill failure',

  createMaterialsBySkillStart: '[admin] create materials by skill start',
  createMaterialsBySkillSuccess: '[admin] create materials by skill success',
  createMaterialsBySkillFailure: '[admin] create materials by skill failure',

  deleteMaterialsBySkillStart: '[admin] delete materials by skill start',
  deleteMaterialsBySkillSuccess: '[admin] delete materials by skill success',
  deleteMaterialsBySkillFailure: '[admin] delete materials by skill failure',

  getMaterialsBySpecStart: '[admin] get materials by spec start',
  getMaterialsBySpecSuccess: '[admin] get materials by spec success',
  getMaterialsBySpecFailure: '[admin] get materials by spec failure',

  createMaterialsBySpecStart: '[admin] create materials by spec start',
  createMaterialsBySpecSuccess: '[admin] create materials by spec success',
  createMaterialsBySpecFailure: '[admin] create materials by spec failure',

  deleteMaterialsBySpecStart: '[admin] delete materials by spec start',
  deleteMaterialsBySpecSuccess: '[admin] delete materials by spec success',
  deleteMaterialsBySpecFailure: '[admin] delete materials by spec failure',

  changeMaterialsStart: '[admin] change materials start',
  changeMaterialsSuccess: '[admin] change materials success',
  changeMaterialsFailure: '[admin] change materials failure',

  changePulseSpecStart: '[admin] change pulse spec start',
  changePulseSpecSuccess: '[admin] change pulse spec success',
  changePulseSpecFailure: '[admin] change pulse spec failure',

  getMaterialsGradesStart: '[admin] get materials grades start',
  getMaterialsGradesSuccess: '[admin] get materials grades success',
  getMaterialsGradesFailure: '[admin] get materials grades failure',

  // specializations
  getSpecializationsStart: '[admin] get specialization list start',
  getSpecializationsSuccess: '[admin] get specialization list success',
  getSpecializationsFailure: '[admin] get specialization list failure',

  createSpecializationStart: '[admin] create specialization start',
  createSpecializationSuccess: '[admin] create specialization success',
  createSpecializationFailure: '[admin] create specialization failure',

  editSpecializationStart: '[admin] edit specialization start',
  editSpecializationSuccess: '[admin] edit specialization success',
  editSpecializationFailure: '[admin] edit specialization failure',

  deleteSpecializationStart: '[admin] delete specialization start',
  deleteSpecializationSuccess: '[admin] delete specialization success',
  deleteSpecializationFailure: '[admin] delete specialization failure',

  importSpecializationsStart: '[admin] import specializations start',
  importSpecializationsSuccess: '[admin] import specializations success',
  importSpecializationsFailed: '[admin] import specializations failed',

  // specializations group
  getSpecializationGroupsStart: '[admin] get specialization group list start',
  getSpecializationGroupsSuccess: '[admin] get specialization group list success',
  getSpecializationGroupsFailure: '[admin] get specialization group list failure',

  createSpecializationGroupStart: '[admin] create specialization group start',
  createSpecializationGroupSuccess: '[admin] create specialization group success',
  createSpecializationGroupFailure: '[admin] create specialization group failure',

  editSpecializationGroupStart: '[admin] edit specialization group start',
  editSpecializationGroupSuccess: '[admin] edit specialization group success',
  editSpecializationGroupFailure: '[admin] edit specialization group failure',

  deleteSpecializationGroupStart: '[admin] delete specialization group start',
  deleteSpecializationGroupSuccess: '[admin] delete specialization group success',
  deleteSpecializationGroupFailure: '[admin] delete specialization group failure',

  // base profile
  getBaseProfileTreeStart: '[admin] get base profile tree start',
  getBaseProfileTreeSuccess: '[admin] get base profile tree success',
  getBaseProfileTreeFailure: '[admin] get base profile tree failure',

  editBaseProfileStart: '[admin] edit base profile start',
  editBaseProfileSuccess: '[admin] edit base profile success',
  editBaseProfileFailure: '[admin] edit base profile failure',

  changeIndicatorsStart: '[admin] change indicators start',
  changeIndicatorsSuccess: '[admin] change indicators success',
  changeIndicatorsFailure: '[admin] change indicators failure',

  deleteBaseProfileStart: '[admin] delete base profile start',
  deleteBaseProfileSuccess: '[admin] delete base profile success',
  deleteBaseProfileFailed: '[admin] delete base profile failed',

  changeScalesStart: '[admin] change scales start',
  changeScalesSuccess: '[admin] change scales success',
  changeScalesFailure: '[admin] change scales failure',

  getQuestionScaleStart: '[admin] get question scale start',
  getQuestionScaleSuccess: '[admin] get question scale success',
  getQuestionScaleFailure: '[admin] get question scale failure',

  changeQuestionScaleStart: '[admin] change question scale start',
  changeQuestionScaleSuccess: '[admin] change question scale success',
  changeQuestionScaleFailure: '[admin] change question scale failure',

  getQuestionTooltipsStart: '[admin] get questions tooltips start',
  getQuestionTooltipsSuccess: '[admin] get questions tooltips success',
  getQuestionTooltipsFailure: '[admin] get questions tooltips failure',

  createQuestionTooltipsStart: '[admin] create questions tooltips start',
  createQuestionTooltipsSuccess: '[admin] create questions tooltips success',
  createQuestionTooltipsFailure: '[admin] create questions tooltips failure',
};

const mutations = {
  [mutationTypes.getSkillsTreeStart](state) {
    state.isLoadingTree = true;
    state.skillsTree = null;
  },
  [mutationTypes.getSkillsTreeSuccess](state, payload) {
    state.isLoadingTree = false;
    state.skillsTree = payload;
  },
  [mutationTypes.getSkillsTreeFailure](state) {
    state.isLoadingTree = false;
  },

  [mutationTypes.getSkillsVersionsStart](state) {
    state.isLoadingVersion = true;
    state.versions = null;
  },
  [mutationTypes.getSkillsVersionsSuccess](state, payload) {
    state.isLoadingVersion = false;
    state.versions = payload;
  },
  [mutationTypes.getSkillsVersionsFailure](state) {
    state.isLoadingVersion = false;
  },

  [mutationTypes.fixateSkillsVersionStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.fixateSkillsVersionSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.fixateSkillsVersionFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.registryMoveStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.registryMoveSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.registryMoveFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.registryEditStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.registryEditSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.registryEditFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getMaterialsByQuestionStart](state) {
    state.isLoading = true;
    state.materialsByQuestion = null;
  },
  [mutationTypes.getMaterialsByQuestionSuccess](state, payload) {
    state.isLoading = false;
    state.materialsByQuestion = payload;
  },
  [mutationTypes.getMaterialsByQuestionFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createMaterialsByQuestionStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMaterialsByQuestionSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMaterialsByQuestionFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMaterialsByQuestionStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteMaterialsByQuestionSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMaterialsByQuestionFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getMaterialsBySkillStart](state) {
    state.isLoading = true;
    state.materialsBySkill = null;
  },
  [mutationTypes.getMaterialsBySkillSuccess](state, payload) {
    state.isLoading = false;
    state.materialsBySkill = payload;
  },
  [mutationTypes.getMaterialsBySkillFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createMaterialsBySkillStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMaterialsBySkillSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMaterialsBySkillFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMaterialsBySkillStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteMaterialsBySkillSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMaterialsBySkillFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getMaterialsBySpecStart](state) {
    state.isLoading = true;
    state.materialsBySpec = null;
  },
  [mutationTypes.getMaterialsBySpecSuccess](state, payload) {
    state.isLoading = false;
    state.materialsBySpec = payload;
  },
  [mutationTypes.getMaterialsBySpecFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createMaterialsBySpecStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMaterialsBySpecSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMaterialsBySpecFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMaterialsBySpecStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteMaterialsBySpecSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMaterialsBySpecFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMaterialsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMaterialsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changePulseSpecStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changePulseSpecSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changePulseSpecFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getMaterialsGradesStart](state) {
    state.isLoading = true;
    state.materialsGrades = null;
  },
  [mutationTypes.getMaterialsGradesSuccess](state, payload) {
    state.isLoading = false;
    state.materialsGrades = payload;
  },
  [mutationTypes.getMaterialsGradesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecializationsStart](state) {
    state.isLoadingSpec = true;
    state.specializations = null;
  },
  [mutationTypes.getSpecializationsSuccess](state, payload) {
    state.isLoadingSpec = false;
    state.specializations = payload;
  },
  [mutationTypes.getSpecializationsFailure](state) {
    state.isLoadingSpec = false;
  },

  [mutationTypes.createSpecializationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createSpecializationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createSpecializationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.editSpecializationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.editSpecializationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.editSpecializationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteSpecializationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteSpecializationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteSpecializationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.importSpecializationsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.importSpecializationsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.importSpecializationsStart](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getSpecializationGroupsStart](state) {
    state.isLoading = true;
    state.specializationGroups = null;
  },
  [mutationTypes.getSpecializationGroupsSuccess](state, payload) {
    state.isLoading = false;
    state.specializationGroups = payload;
  },
  [mutationTypes.getSpecializationGroupsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createSpecializationGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createSpecializationGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createSpecializationGroupFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.editSpecializationGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.editSpecializationGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.editSpecializationGroupStart](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteSpecializationGroupStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteSpecializationGroupSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteSpecializationGroupFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getBaseProfileTreeStart](state) {
    state.isLoading = true;
    state.baseProfileTree = null;
  },
  [mutationTypes.getBaseProfileTreeSuccess](state, payload) {
    state.isLoading = false;
    state.baseProfileTree = payload;
  },
  [mutationTypes.getBaseProfileTreeFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.editBaseProfileStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.editBaseProfileSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.editBaseProfileFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeIndicatorsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeIndicatorsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeIndicatorsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeScalesStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeScalesSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeScalesFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getQuestionScaleStart](state) {
    state.isLoading = true;
    state.questionScale = null;
  },
  [mutationTypes.getQuestionScaleSuccess](state, payload) {
    state.isLoading = false;
    state.questionScale = payload;
  },
  [mutationTypes.getQuestionScaleFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeQuestionScaleStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeQuestionScaleSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeQuestionScaleFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getQuestionTooltipsStart](state) {
    state.isLoadingQuestionTooltips = true;
    state.questionTooltips = null;
  },
  [mutationTypes.getQuestionTooltipsSuccess](state, payload) {
    state.isLoadingQuestionTooltips = false;
    state.questionTooltips = payload;
  },
  [mutationTypes.getQuestionTooltipsFailure](state) {
    state.isLoadingQuestionTooltips = false;
  },

  [mutationTypes.createQuestionTooltipsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createQuestionTooltipsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createQuestionTooltipsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
