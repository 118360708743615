<template>
  <div :class="classes">
    <v-container v-if="title || !innerNumbers" fluid px-0>
      <v-row align="center">
        <v-col v-if="title" cols="12" sm="auto">
          <span>{{ title }}</span>
        </v-col>
        <v-col v-if="!innerNumbers" cols="12" sm="auto" class="ms-auto">
          <v-skeleton-loader v-if="isLoading" width="50" type="text"></v-skeleton-loader>
          <span v-else class="app-text-family--bold">{{ value }}/{{ total }}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-skeleton-loader v-if="isLoading" type="image" height="24"></v-skeleton-loader>
    <v-tooltip
      v-else
      :disabled="!description"
      bottom
      :max-width="$vuetify.breakpoint.mobile ? 300 : 520"
      color="secondary"
    >
      <template #activator="{on, attrs}">
        <div
          class="progress-line-bar"
          :class="{'progress-line-bar--hover': description}"
          v-bind="attrs"
          v-on="on"
        >
          <div
            v-if="value"
            class="progress-line-bar-gradient"
            :class="color"
            :style="[
              {width: `${progressPercentage}%`},
              {background: `linear-gradient(90deg, ${baseColor} 0%, currentColor 90%)`},
            ]"
          >
            <span
              v-if="innerNumbers"
              class="app-text-size--extra-small app-text-family--bold progress-line-bar-gradient-value"
              >{{ value }}</span
            >
          </div>
          <span
            v-else-if="innerNumbers"
            class="app-text-size--extra-small app-text-family--bold progress-line-bar-value"
            >{{ value }}</span
          >
          <span
            v-if="innerNumbers"
            class="ms-auto app-text-size--extra-small app-text-family--bold progress-line-bar-total"
            >{{ total }}</span
          >
        </div>
      </template>
      <article class="py-1">
        <p>{{ description }}</p>
      </article>
    </v-tooltip>
  </div>
</template>

<script>
import {themeMixin} from '@/mixins/theme';

export default {
  name: 'AppProgressLineBar',
  mixins: [themeMixin],
  props: {
    title: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: Number,
      default: 0,
    },
    total: {
      required: false,
      type: Number,
      default: 100,
    },
    color: {
      required: false,
      type: String,
      default: 'success--text',
    },
    description: {
      required: false,
      type: String,
      default: null,
    },
    innerNumbers: {
      required: false,
      type: Boolean,
      default: false,
    },
    classes: {
      required: false,
      type: String,
      default: null,
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progressPercentage() {
      return (this.value * 100) / this.total;
    },
    baseColor() {
      return this.activeTheme === 'dark' ? this.colors.secondary : '#FFFFFF';
    },
  },
};
</script>

<style scoped lang="scss">
.progress-line-bar {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: rem(24px);
  width: 100%;
  border: $border-width solid;
  border-radius: $border-radius-root;
  transition: #{$transition-duration}s;

  .theme--light & {
    border-color: rgba(0, 0, 0, 0.04);
    background-color: get($blue, 'lighten-7');

    &--hover {
      &:hover {
        border-color: get($grey, 'darken-5');
        background-color: get($shades, 'white');
      }
    }
  }
  .theme--dark & {
    border-color: get($grey, 'accent-5');
    background-color: get($grey, 'darken-5');

    &--hover {
      &:hover {
        border-color: get($shades, 'white');
      }
    }
  }
  &-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &-value {
      margin-left: auto;
      padding-right: 0.5rem;
      color: get($shades, 'white');
    }
  }
  &-value {
    padding-left: 0.5rem;
  }
  &-total {
    padding-right: 0.5rem;
    color: get($grey, 'lighten-1');
    transition: color #{$transition-duration}s;
  }
}
</style>
