import Vue from 'vue';
import AppAlert from '@/components/common/Alert';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/common/Avatar';
import AppBottomMenu from '@/components/common/BottomMenu';
import AppBreadcrumbs from '@/components/common/Breadcrumbs';
import AppTabs from '@/components/common/Tabs';
import AppImage from '@/components/common/Image';
import AppTitle from '@/components/common/Title';
import AppTitleWithButtons from '@/components/common/TitleWithButtons';
import AppFieldHint from '@/components/common/FieldHint';
import AppFieldTitleWithTooltip from '@/components/common/FieldTitleWithTooltip';
import AppRichTextArea from '@/components/common/rich-text-area/RichTextArea';
import AppItemHint from '@/components/common/ItemHint';
import AppLoading from '@/components/common/Loading';
import AppLogo from '@/components/common/Logo';
import AppProgressLineBar from '@/components/common/ProgressLineBar';
import AppExpansionFiltersBar from '@/components/common/ExpansionFiltersBar';
import AppDatePickerDual from '@/components/common/date-picker-dual/DatePickerDual';
import AppMonthPickerDual from '@/components/common/month-picker-dual/MonthPickerDual';
import AppConfirmDialog from '@/components/dialogs/ConfirmDialog';
import AppInfoDialog from '@/components/dialogs/InfoDialog';

Vue.component('AppAlert', AppAlert);
Vue.component('AppSvgIcon', AppSvgIcon);
Vue.component('AppAvatar', AppAvatar);
Vue.component('AppBottomMenu', AppBottomMenu);
Vue.component('AppBreadcrumbs', AppBreadcrumbs);
Vue.component('AppImage', AppImage);
Vue.component('AppTitle', AppTitle);
Vue.component('AppTabs', AppTabs);
Vue.component('AppTitleWithButtons', AppTitleWithButtons);
Vue.component('AppFieldHint', AppFieldHint);
Vue.component('AppFieldTitleWithTooltip', AppFieldTitleWithTooltip);
Vue.component('AppRichTextArea', AppRichTextArea);
Vue.component('AppItemHint', AppItemHint);
Vue.component('AppLoading', AppLoading);
Vue.component('AppLogo', AppLogo);
Vue.component('AppProgressLineBar', AppProgressLineBar);
Vue.component('AppExpansionFiltersBar', AppExpansionFiltersBar);
Vue.component('AppDatePickerDual', AppDatePickerDual);
Vue.component('AppMonthPickerDual', AppMonthPickerDual);
Vue.component('AppConfirmDialog', AppConfirmDialog);
Vue.component('AppInfoDialog', AppInfoDialog);
