export const dialogsMixin = {
  data() {
    return {
      isDialogVisible: {},
    };
  },
  methods: {
    toggleVisibleDialog({name, state}) {
      this.isDialogVisible[name] = state;
    },
  },
};
