import axios from '@/api/axios';

const createTargetAsUser = (data) => axios.post(`/target/as_user`, data);

const createTargetAsCurator = (data) => axios.post(`/target/as_curator`, data);

const changeTarget = (targetId, data) => axios.put(`/target/${targetId}`, data);

const gradeTarget = (targetId, data) => axios.put(`/target/${targetId}/grade`, data);

const moveTarget = (targetId, status) => axios.put(`/target/${targetId}/move/${status}`);

const deleteTarget = (targetId) => axios.delete(`/target/${targetId}`);

const changeCurators = (targetId, data) => axios.put(`/target/${targetId}/curators`, data);

const getComments = (targetId) => axios.get(`/target/${targetId}/comments`);

const getRights = (targetId) => axios.get(`/target/${targetId}/rights`);

export default {
  createTargetAsUser,
  createTargetAsCurator,

  changeTarget,
  gradeTarget,
  moveTarget,
  deleteTarget,
  changeCurators,

  getComments,
  getRights,
};
