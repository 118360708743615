import roadmap360Api from '@/api/roadmap360';
import {mutationTypes} from '@/store/modules/roadmap360/mutations';

export const actionsTypes = {
  getIface: '[roadmap360] get iface',
  getVersions: '[roadmap360] get versions',
  getVersionsBySpec: '[roadmap360] get versions by spec',
  getHeader: '[roadmap360] get header',
  getSkills: '[roadmap360] get skills',
  getHeaderByTestingId: '[roadmap360] get header by testing id',
  getCommonMaterials: '[roadmap360] get common materials',
  getSkillsMaterials: '[roadmap360] get skills materials',
  getBlocksMaterials: '[roadmap360] get blocks materials',
  getBlockProgress: '[roadmap360] get block progress',

  createRoadmap: '[roadmap360] create roadmap',
  fixateRoadmap: '[roadmap360] fixate roadmap',
  changeHeader: '[roadmap360] change header',

  createMaterial: '[roadmap360] create material',
  moveMaterials: '[roadmap360] move materials',
  showMaterials: '[roadmap360] show materials',
  changeMaterial: '[roadmap360] change material',
  deleteMaterials: '[roadmap360] delete materials',

  changeBlock: '[roadmap360] change block',
};

const actions = {
  [actionsTypes.getIface](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getIfaceStart);
      roadmap360Api
        .getIface()
        .then((response) => {
          context.commit(mutationTypes.getIfaceSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getIfaceFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getVersions](context, {userId, company}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getVersionsStart);
      roadmap360Api
        .getVersions(userId, company)
        .then((response) => {
          context.commit(mutationTypes.getVersionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getVersionsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getVersionsBySpec](context, {userId, specCode, company}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getVersionsBySpecStart);
      roadmap360Api
        .getVersionsBySpec(userId, specCode, company)
        .then((response) => {
          context.commit(mutationTypes.getVersionsBySpecSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getVersionsBySpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getHeader](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getHeaderStart);
      roadmap360Api
        .getHeader(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getHeaderSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getHeaderFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSkills](context, {id, userId, companyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsStart);
      roadmap360Api
        .getSkills(id, userId, companyId)
        .then((response) => {
          context.commit(mutationTypes.getSkillsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getHeaderByTestingId](context, testing360Id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getHeaderByTestingIdStart);
      roadmap360Api
        .getHeaderByTestingId(testing360Id)
        .then((response) => {
          context.commit(mutationTypes.getHeaderByTestingIdSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getHeaderByTestingIdFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCommonMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCommonMaterialsStart);
      roadmap360Api
        .getCommonMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getCommonMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCommonMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSkillsMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsMaterialsStart);
      roadmap360Api
        .getSkillsMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getSkillsMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getBlocksMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBlocksMaterialsStart);
      roadmap360Api
        .getBlocksMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getBlocksMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBlocksMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getBlockProgress](context, {id, userId, blockId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBlockProgressStart);
      roadmap360Api
        .getBlockProgress(id, userId, blockId)
        .then((response) => {
          context.commit(mutationTypes.getBlockProgressSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBlockProgressFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.createRoadmap](context, {testing360Id, recreate}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRoadmapStart);
      roadmap360Api
        .createRoadmap(testing360Id, recreate)
        .then((response) => {
          context.commit(mutationTypes.createRoadmapSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createRoadmapFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.fixateRoadmap](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.fixateRoadmapStart);
      roadmap360Api
        .fixateRoadmap(id, userId)
        .then((response) => {
          context.commit(mutationTypes.fixateRoadmapSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.fixateRoadmapFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeHeader](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeHeaderStart);
      roadmap360Api
        .changeHeader(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeHeaderSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeHeaderFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.createMaterial](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMaterialStart);
      roadmap360Api
        .createMaterial(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.createMaterialSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMaterialFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.moveMaterials](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.moveMaterialsStart);
      roadmap360Api
        .moveMaterials(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.moveMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.moveMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.showMaterials](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.showMaterialsStart);
      roadmap360Api
        .showMaterials(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.showMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.showMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeMaterial](context, {userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMaterialStart);
      roadmap360Api
        .changeMaterial(userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeMaterialSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMaterialFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMaterials](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMaterialsStart);
      roadmap360Api
        .deleteMaterials(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.deleteMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeBlock](context, {userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeBlockStart);
      roadmap360Api
        .changeBlock(userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeBlockSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeBlockFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
