export default {
  plans: null,
  assignedAdaptations: null,
  userAdaptation: null,
  adaptations: null,
  adaptationUserTasks: null,
  tasks: null,
  isLoading: false,
  isLoadingTasks: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
