<i18n>
{
  "ru": {},
  "en": {}
}
</i18n>
<template>
  <div class="app-image position-relative" :class="classes">
    <figure>
      <v-img
        v-if="loadingError || !src"
        :max-width="maxWidth"
        :aspect-ratio="square ? 1 : 16 / 9"
        alt="image-empty"
        class="grey lighten-2"
        :class="{rounded}"
      >
        <div class="fill-height d-flex align-center justify-center">
          <v-icon dark :size="100" v-text="'mdi-image'"></v-icon>
        </div>
      </v-img>
      <v-img
        v-else
        :src="src"
        :max-width="maxWidth"
        :aspect-ratio="square ? 1 : 16 / 9"
        alt="image"
        :contain="contain"
        class="grey lighten-2"
        :class="[{rounded}, {outlined}]"
        @error="loadingError = true"
      >
        <template #placeholder>
          <div class="fill-height d-flex align-center justify-center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </div>
        </template>
      </v-img>
    </figure>
    <template v-if="isEditable">
      <input
        ref="fileInput"
        type="file"
        name="document"
        accept="image/*"
        class="d-none"
        @change="onFileSelected"
      />
      <v-btn
        absolute
        right
        top
        fab
        small
        color="primary"
        class="primary-contrast--text"
        @click="$refs.fileInput.click()"
      >
        <v-icon v-text="'mdi-pencil'"></v-icon>
      </v-btn>
    </template>
    <template v-if="isDeletable">
      <v-btn absolute right bottom fab dark small color="warning" @click="onDeleteImage">
        <v-icon v-text="'mdi-delete'"></v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppImage',
  props: {
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      required: false,
      default: false,
    },
    src: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 300,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    contain: {
      type: Boolean,
      required: false,
      default: false,
    },
    square: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loadingError: false,
    };
  },
  methods: {
    onFileSelected({target}) {
      this.$emit('change', target.files[0]);
    },
    onDeleteImage() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
.app-image {
  .v-image.outlined {
    border-width: $border-width;
    border-style: solid;
  }
}
</style>
