<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <v-dialog v-model="isVisibleState" :max-width="maxWidth" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <app-title v-bind="titleAttrs">{{ title }}</app-title>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-container fluid pa-0>
          <v-row justify="center">
            <v-col v-if="actionButton" cols="12" sm="6">
              <v-btn
                :disabled="isSubmitting || isDisabled"
                block
                depressed
                color="primary"
                class="primary-contrast--text"
                @click.prevent="action"
              >
                {{ actionButton }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                v-if="actionButton"
                :disabled="isSubmitting"
                block
                outlined
                @click.prevent="isVisibleState = false"
              >
                {{ button ? button : $t('buttons.close') }}
              </v-btn>
              <v-btn
                v-else
                :disabled="isSubmitting"
                block
                depressed
                color="primary"
                class="primary-contrast--text"
                @click.prevent="isVisibleState = false"
              >
                {{ button ? button : $t('buttons.close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppInfoDialog',
  props: {
    name: {
      required: true,
      type: String,
      default: '',
    },
    maxWidth: {
      required: false,
      type: Number,
      default: 500,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    titleAttrs: {
      required: false,
      type: Object,
      default: () => {
        return {size: 'extra-small'};
      },
    },
    button: {
      required: false,
      type: String,
      default: '',
    },
    actionButton: {
      required: false,
      type: String,
      default: '',
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      required: false,
      type: Boolean,
      default: false,
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisibleState: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.toggleVisible(value);
      },
    },
  },
  watch: {
    isVisible(value) {
      if (!value) this.$emit('close');
    },
  },
  methods: {
    toggleVisible(state) {
      this.$emit('toggle-visible', {name: this.name, state});
    },
    action() {
      this.$emit('action');
    },
  },
};
</script>
