export default {
  report: null,
  versions: null,
  versionsBySpec: null,
  iface: null,
  header: null,
  commonMaterials: null,
  skillsMaterials: null,
  blocksMaterials: null,
  blockProgress: null,
  isLoading: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
