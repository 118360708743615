export default {
  report360: null,
  reportPersonal: null,
  reportSelf: null,
  reportSelfVersions: null,
  reportTeam: null,
  reportTeamSpecs: null,
  reportParams: null,
  reportLink: null,
  isLoading: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
