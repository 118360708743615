export const gettersTypes = {
  isChief: '[roadmap] isChief',
};

const getters = {
  [gettersTypes.isChief]: (state) => {
    if (!state.iface) return false;
    return state.iface.is_chief;
  },
};

export default getters;
