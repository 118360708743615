import axios from '@/api/axios';

const getReportSelfVersions = (userId, specCode, skillType, teamId) =>
  axios.get(`/reports/${userId}/${specCode}/${skillType}${teamId ? `?team=${teamId}` : ''}`);

const getReportSelf = (reportName, userId, specCode, teamId, format = '', version) =>
  axios.get(
    `/report/${reportName}/${userId}?spec=${specCode}&format=${format}&version=${version}${
      teamId ? `&team=${teamId}` : ''
    }`
  );

const getReport360 = (reportName, userId, testId, teamId, format = '') =>
  axios.get(
    `/report/${reportName}/${userId}?spec=${testId}&format=${format}${
      teamId ? `&team=${teamId}` : ''
    }`
  );

const getReportPersonal = (reportName, userId, teamId, format = '') =>
  axios.get(
    `/report/${reportName}/${userId}?spec=personal&format=${format}${
      teamId ? `&team=${teamId}` : ''
    }`
  );

const getReportTeam = (reportName, teamId, teamIds, specCode, format = '') =>
  axios.get(
    `/report/${reportName}/${teamId}?team=${teamIds}&format=${format}${
      specCode ? `&spec=${specCode}` : ''
    }`
  );

const getReportTeamSpecs = (skillType, teamId) =>
  axios.get(`/report/team_report_specializations/${skillType}/${teamId}`);

const getReportParams = (reportName, targetId) =>
  axios.get(`/report/${reportName}/${targetId}/params`);

const setReportParams = (reportName, targetId, data) =>
  axios.post(`/report/${reportName}/${targetId}/params`, data);

const uploadDiagram = (reportName, targetId, data) =>
  axios.post(`/report/${reportName}/${targetId}/upload`, data);

export default {
  getReportSelfVersions,
  getReportSelf,
  getReport360,
  getReportPersonal,
  getReportTeam,
  getReportTeamSpecs,
  getReportParams,
  setReportParams,
  uploadDiagram,
};
