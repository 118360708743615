<template>
  <div class="redactor-x-surveys">
    <v-row no-gutters align="center" class="pb-0">
      <!--      <span v-if="label" class="redactor-x-surveys__label mr-1">{{ label }}</span>-->
      <app-field-title-with-tooltip
        :field-title="fieldTitle"
        :field-title-tooltip="fieldTitleTooltip"
      >
        <template v-if="shouldShowLabelControls" #label-controls>
          <slot name="label-controls"></slot>
        </template>
      </app-field-title-with-tooltip>
    </v-row>

    <textarea
      :id="redactorId"
      :ref="redactorId"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      class="color-white"
      :style="styles"
    />

    <span v-if="hint" class="redactor-x-surveys__label">{{ hint }}</span>

    <slot name="hint"></slot>
  </div>
</template>

<script>
import Vue from 'vue';
// import "./plugins-x/handle/handle.css";
// стили в шапке подключаются
// import "./css/rich-text-area.min.css";
import RedactorX from './js/redactorx';
import './plugins-x/alignment/alignment.min';
import './plugins-x/counter/counter.min';
import './plugins-x/imageposition/imageposition.min';
import './plugins-x/imageresize/imageresize.min';
import './plugins-x/filelink/filelink';
import './plugins-x/inlineformat/inlineformat.min';
import './plugins-x/removeformat/removeformat.min';
import './plugins-x/handle/handle';
import './langs-x/ru';
import './langs-x/en';
import {themeMixin} from '@/mixins/theme';
import {getNormalizedSlot} from '@/helpers/utils';

export default {
  name: 'AppRedactorX',

  mixins: [themeMixin],

  props: {
    id: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: null,
    },

    name: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    fieldTitle: {
      type: String,
      default: '',
    },

    fieldTitleTooltip: {
      type: String,
      default: '',
    },

    config: {
      type: Object,
      default: function () {
        return {};
      },
    },

    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultPlugins: [
        'alignment',
        'removeformat',
        'inlineformat',
        // "fontcolor",
        'limiter',
        'table',
        'video',
      ],
      defaultFontfamily: [
        'Arial',
        'Helvetica',
        'Georgia',
        'Times New Roman',
        'Monospace',
        'Montserrat',
        'Anonymous Pro',
        'Inter',
        'Roboto',
      ],
      redactorx: false,
      localConf: {
        minHeight: '150px',
      },
    };
  },

  computed: {
    styles() {
      return {
        '--var-error-color': this.colors.error,
      };
    },

    redactorId() {
      return this.id || 'rich-text-area-content' + this.getPerf;
    },

    getPerf() {
      return performance.now().toString().replace('.', '7');
    },

    shouldShowLabelControls() {
      return getNormalizedSlot('label-controls', this);
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    value(newValue, oldValue) {
      if (!this.redactorx.editor.isFocus()) {
        this.redactorx.app.$element.val(newValue || '');
        this.redactorx.app.editor.setContent({html: newValue || ''});
      }
    },
    invalid() {
      this.validate();
    },
  },

  created() {
    const me = this;
    this.localConf = this.config;
    // eslint-disable-next-line no-prototype-builtins
    if (this.localConf.hasOwnProperty('plugins')) {
      //
    } else {
      this.localConf.plugins = this.defaultPlugins;
    }
    this.localConf.variables = ['username', 'email', 'password', 'name', 'lastname', 'country'];
    // eslint-disable-next-line no-prototype-builtins
    if (this.localConf.hasOwnProperty('fontfamily')) {
      //
    } else {
      this.localConf.fontfamily = this.defaultFontfamily;
    }
    this.localConf.editor = this.localConf.editor ? this.localConf.editor : {};
    this.localConf.editor.lang = this.$i18n.locale;
    this.localConf.linkTarget = '_blank';
    this.localConf.imagePosition = true;
    this.localConf.control = true;
    this.localConf.context = true;
    // this.localConf.toolbar = false;
    this.localConf.buttons = {
      topbar: ['undo', 'redo', 'shortcut'],
    };
    if (this.localConf.fileUpload) {
      this.localConf.plugins.push('filelink');
      this.localConf.filelink = {
        name: 'file',
        upload: this.localConf.fileUpload,
      };
    }
    if (this.localConf.imageUpload) {
      this.localConf.plugins.push('imageposition');
      this.localConf.plugins.push('imageresize');
      this.localConf.image = {
        // upload: this.localConf.imageUpload,
        // multiple: false,
        // name: "file"
        newtab: true,
        types: ['image/png', 'image/jpeg'],
        upload: async function (upload, data) {
          me.$emit('upload-file', {
            files: data.files,
            successCallback: (files) => {
              // loop files
              // create response
              let response = {};
              files.forEach((f, idx) => {
                response[`file${idx === 0 ? '' : '-' + idx}`] = {
                  link: `${window.location.protocol}//${window.location.host}${f.path}`,
                  class: 'rx-image',
                  url: f.path,
                  id: f.uid,
                };
              });

              // call complete
              upload.complete(response, data.e);
            },
            errorCallback: (e) => {
              window.console.log(e);
            },
          });
        },
      };
    }
    if (this.localConf.handle) {
      this.localConf.handle = {
        url: this.localConf.handle,
        start: this.localConf.handleStart,
      };
    }
    this.localConf.embed = {
      script: true,
    };
  },

  beforeDestroy() {
    this.destroy();
  },

  mounted() {
    this.init();
  },

  methods: {
    insertText(text) {
      this.redactorx.editor.insertContent({html: text});
    },

    focused() {
      const toolbar = document.querySelectorAll('[data-is-rich-text-area-toolbar]');
      toolbar.forEach((tb) => {
        const isTb = Boolean(tb.dataset.isRedactorToolbar);
        if (isTb) {
          tb.classList.remove('d-none');
        }
      });
      this.validate();
    },

    blurred() {
      const toolbar = document.querySelectorAll('[data-is-rich-text-area-toolbar]');
      toolbar.forEach((tb) => {
        const isTb = Boolean(tb.dataset.isRedactorToolbar);
        if (isTb) {
          tb.classList.add('d-none');
        }
      });
      this.validate();
    },

    validate() {
      const el = document.getElementById(this.id);
      // console.log(this.rich-text-area.container);
      if (!el) return;
      const parent = el.parentNode;
      if (!parent) return;
      const redactorx = parent.getElementsByClassName('rx-container');
      if (redactorx && redactorx.length) {
        if (this.invalid) {
          if (!redactorx[0].classList.contains('rx-container__invalid'))
            redactorx[0].classList.add('rx-container__invalid');
          // setAttribute('style', 'border-color: #ff5252!important');
        } else {
          if (redactorx[0].classList.contains('rx-container__invalid'))
            redactorx[0].classList.remove('rx-container__invalid');
        }
      }
    },

    init() {
      let me = this;
      let callbacks = {
        changed: function (html) {
          me.handleInput(html);
          return html;
        },
        focus: this.focused,
        blur: this.blurred,
      };
      let subscribe = {
        'editor.change': function (event) {
          let html = event.get('html');
          me.handleInput(html);
          return html;
        },
        focus: this.focused,
        blur: this.blurred,
      };

      const externalToolbar = document.querySelector('[data-is-rich-text-area-toolbar]');
      if (externalToolbar) {
        this.localConf.toolbarExternal = `#${externalToolbar.id}`;
      }

      // extend config
      Vue.set(this.localConf, 'callbacks', callbacks);
      if (typeof this.localConf.subscribe === 'undefined') {
        Vue.set(this.localConf, 'subscribe', subscribe);
      } else {
        this.localConf.subscribe['editor.change'] = subscribe['editor.change'];
      }

      // call RedactorX
      let app = RedactorX(this.$refs[this.redactorId], this.localConf);

      // set instance
      this.redactorx = app;
      this.$parent.redactorx = app;
      // app.editor.lang = "ru";
      // app.rootElement.addEventListener("focusin", this.focused);
      this.validate();
    },

    handleInput(val) {
      this.$emit('input', val);
      this.validate();
    },

    destroy() {
      // Call destroy on rich-text-area to cleanup event handlers
      this.redactorx.destroy();
      this.$parent.redactorx.destroy();
      RedactorX(this.$refs[this.redactorId], 'destroy');

      // unset instance for garbage collection
      this.redactorx = null;
      this.$parent.redactorx = null;
    },
    async filesUpload(files) {
      return await this['app/uploadFiles']({files});
    },
  },
};
</script>

<style lang="scss" scoped>
.redactor-x-surveys {
  &__label {
    font-size: 13px;
    color: #00000099;
  }
}

.theme--dark .redactor-x-surveys {
  &__label {
    color: #ffffff;
  }
}
</style>
