export const gettersTypes = {
  numOfAvailablePersonalTests: '[testing] number of available personal tests',
};

const getters = {
  [gettersTypes.numOfAvailablePersonalTests]: (state) => {
    if (!state.metadataPersonalTests) return null;
    return state.metadataPersonalTests.filter((test) => test.is_available).length;
  },
};

export default getters;
