<template>
  <v-tabs
    :key="notMatch"
    optional
    background-color="info"
    slider-color="blue"
    color="blue"
    active-class="blue--text"
    show-arrows
  >
    <template v-for="tab in data">
      <v-tab
        v-if="tab.key"
        :key="tab.key"
        :disabled="tab.isDisabled"
        :to="
          Object.assign(
            {},
            {name: tab.key},
            tab.params ? {params: tab.params} : {},
            tab.query ? {query: tab.query} : {}
          )
        "
        exact-path
      >
        <span>{{ tab.title }}</span>
        <v-badge v-if="tab.count" inline color="warning" :content="tab.count"></v-badge>
      </v-tab>
      <v-menu v-else-if="tab.submenu && tab.submenu.length" :key="tab.key" offset-y open-on-hover>
        <template #activator="{on, attrs}">
          <v-tab
            :to="{name: getRouteName(tab.submenu) || tab.submenu[0].key}"
            exact-path
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ getTitleTab(tab) }}</span>
            <v-badge
              v-if="tab.count"
              inline
              color="warning"
              class="ms-1"
              :content="tab.count"
            ></v-badge>
            <v-icon right v-text="'mdi-chevron-down'"></v-icon>
          </v-tab>
        </template>
        <v-list dense class="py-0">
          <v-list-item
            v-for="tabSub in tab.submenu"
            :key="tabSub.key"
            :to="{name: tabSub.key}"
            :disabled="tabSub.isDisabled"
            color="blue"
            exact-path
          >
            <v-list-item-title>{{ tabSub.title }}</v-list-item-title>
            <v-list-item-action v-if="tabSub.count">
              <v-badge inline color="warning" :content="tabSub.count"></v-badge>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-tabs>
</template>

<script>
export default {
  name: 'AppTabs',
  props: {
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    notMatch() {
      if (!this.data || !this.data.length) return true;

      let keys = [];

      this.data.forEach((tab) => {
        if (tab.key) keys.push(tab.key);
        if (tab.submenu && tab.submenu.length) {
          keys = keys.concat(tab.submenu.map((tabSub) => tabSub.key));
        }
      });
      return !keys.includes(this.$route.name);
    },
  },
  methods: {
    getRouteName(submenu) {
      const currentRoute = submenu.find((el) => el.key === this.$route.name);
      return currentRoute ? currentRoute.key : undefined;
    },
    getTitleTab(tab) {
      const currentTitle = tab.submenu.find((el) => el.key === this.$route.name);
      return currentTitle ? currentTitle.title : tab.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs {
  &::v-deep .v-tabs-slider-wrapper {
    pointer-events: none;
  }
  &::v-deep .v-icon {
    @include font-size(get($text-sizes, 'middle'));
  }
}
</style>
