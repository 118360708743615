export const gettersTypes = {
  freeLicenses: '[license] free licenses',
};

const getters = {
  [gettersTypes.freeLicenses]: (state, getters, rootState) => {
    let result = {motivation: [], teamRoles: [], test360: []};

    if (!state.licenses) return result;

    const allFreeLicenses = state.licenses.filter(
      (license) => license.is_free && license.user_id === rootState.auth.currentUser.user_id
    );

    result.motivation = allFreeLicenses.filter((license) => license.report_code === 'motivation');
    result.teamRoles = allFreeLicenses.filter((license) => license.report_code === 'team_roles');
    result.test360 = allFreeLicenses.filter((license) => license.report_code === 'test360');

    return result;
  },
};

export default getters;
