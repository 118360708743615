class YandexModule {
  constructor() {
    this.counterId = null;
    this.visitParams = {};
  }
  standartLog() {
    if (this['msg']) {
      console.log(this['msg']);
    }
  }
  initMonitoring(id) {
    const me = this;
    me.counterId = id;
    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) {
          return;
        }
      }
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
    // eslint-disable-next-line no-undef
    ym(me.counterId, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
    // SEO.initializedMetrics.push('yandex'); // Этим занимается родитель теперь
    console.log(`Metric initialized: Yandex ${id}`);
    return true;
  }

  registerHit(url, options) {
    options = options ? options : {};
    options['callback'] = this.standartLog;
    options['ctx'] = {
      msg: `Зарегистрирован переход -> ${url} в Yandex.`,
    };
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'hit', url, options);
    return true;
  }

  registerTarget(targetId) {
    debugger;
    console.log('Wow');
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'reachGoal', targetId, this.visitParams, this.standartLog, {
      msg: `Достигнута цель ${targetId} в Yandex`,
    });
    return true;
  }

  reportVisitParams() {
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'params', this.visitParams);
    return true;
  }

  registerExternalLink(link) {
    const options = {
      callback: this.standartLog,
      ctx: {
        msg: `Зарегистрирован внешний переход -> ${link} в Yandex.`,
      },
      params: this.visitParams,
    };
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'extLink', link, options);
    return true;
  }

  assignUser(userId) {
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'setUserID', parseInt(userId));
    this.visitParams['UserID'] = userId;
    this.reportVisitParams();
    // eslint-disable-next-line no-undef
    ym(this.counterId, 'userParams', {
      UserID: userId,
    });
  }
}

export default YandexModule;
