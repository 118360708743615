<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <v-dialog v-model="isVisibleState" fullscreen>
    <v-card class="position-relative">
      <v-btn absolute plain icon top right @click="isVisibleState = false">
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
      <v-card-title>
        <app-title size="normal" weight="bold">{{ test.title }}</app-title>
      </v-card-title>
      <v-card-text>
        <iframe
          v-if="test.href"
          :src="test.href"
          width="100%"
          :height="$vuetify.breakpoint.mobile ? '100%' : 700"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDemoTestingPersonalDialog',
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    test: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isVisibleState: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.toggleVisible(value);
      },
    },
  },
  methods: {
    toggleVisible(state) {
      this.$emit('toggle-visible', {name: 'demoTestingPersonal', state});
    },
  },
};
</script>
