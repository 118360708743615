import axios from '@/api/axios';

const getDashboardByUser = (userId) => axios.get(`/dashboard/user/${userId}`);

const getDashboardByCompany = (companyId) => axios.get(`/dashboard/company/${companyId}`);

const changeDashboard = (companyId, data) => axios.put(`/dashboard/company/${companyId}`, data);

// blocks
const getUser = (userId) => axios.get(`/dashboard/user/${userId}/blocks/common`);

const getRoadmaps = (userId) => axios.get(`/dashboard/user/${userId}/blocks/roadmaps`);

const getRoadmapsSummary = (userId) => axios.get(`/dashboard/user/${userId}/blocks/board_summary`);

const getAdaptationsSummary = (userId) =>
  axios.get(`/dashboard/user/${userId}/blocks/adaptation_summary`);

const getSpecsWithGrades360 = (userId) =>
  axios.get(`/dashboard/user/${userId}/blocks/diagram360/specs`);

const getHistoryGrades360 = (userId, specCode, dateStart, dateEnd) => {
  const params = dateStart && dateEnd ? `?date_start=${dateStart}&date_end=${dateEnd}` : '';

  return axios.get(
    `/dashboard/user/${userId}/blocks/diagram360/specs/${specCode}/history${params}`
  );
};

const getDiagram360BySpec = (userId, specCode) =>
  axios.get(`/dashboard/user/${userId}/blocks/diagram360/specs/${specCode}`);

const getTests = (userId) => axios.get(`/dashboard/user/${userId}/blocks/knowledge`);

const getTargets = (userId) => axios.get(`/dashboard/user/${userId}/blocks/targets`);

// personal blocks
const getPotential = (userId) => axios.get(`/dashboard/user/${userId}/blocks/ind_comp`);

const getConflictness = (userId) => axios.get(`/dashboard/user/${userId}/blocks/conflictness`);

const getManagementStyle = (userId) => axios.get(`/dashboard/user/${userId}/blocks/manage_style`);

const getTeamRoles = (userId) => axios.get(`/dashboard/user/${userId}/blocks/roles_belbin`);

//banner
const getBannerStatus = (userId) => axios.get(`/dashboard/user/${userId}/adaptation_banner`);

export default {
  getDashboardByUser,
  getDashboardByCompany,
  changeDashboard,

  getUser,
  getRoadmaps,
  getRoadmapsSummary,
  getAdaptationsSummary,
  getSpecsWithGrades360,
  getHistoryGrades360,
  getDiagram360BySpec,
  getTests,
  getTargets,

  getPotential,
  getConflictness,
  getManagementStyle,
  getTeamRoles,

  getBannerStatus,
};
