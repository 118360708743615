export const mutationTypes = {
  getCardsStart: '[board] get cards start',
  getCardsSuccess: '[board] get cards success',
  getCardsFailure: '[board] get cards failure',

  getCardStart: '[board] get card start',
  getCardSuccess: '[board] get card success',
  getCardFailure: '[board] get card failure',

  getRoadmapsStart: '[board] get roadmaps start',
  getRoadmapsSuccess: '[board] get roadmaps success',
  getRoadmapsFailure: '[board] get roadmaps failure',

  changeShowCardsStart: '[board] change show cards start',
  changeShowCardsSuccess: '[board] change show cards success',
  changeShowCardsFailure: '[board] change show cards failure',
};

const mutations = {
  [mutationTypes.getCardsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCardsSuccess](state, payload) {
    state.isLoading = false;
    state.cards = payload;
  },
  [mutationTypes.getCardsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCardStart](state) {
    state.isLoading = true;
    state.card = null;
  },
  [mutationTypes.getCardSuccess](state, payload) {
    state.isLoading = false;
    state.card = payload;
  },
  [mutationTypes.getCardFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRoadmapsStart](state) {
    state.isLoading = true;
    state.roadmaps = null;
  },
  [mutationTypes.getRoadmapsSuccess](state, payload) {
    state.isLoading = false;
    state.roadmaps = payload;
  },
  [mutationTypes.getRoadmapsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeShowCardsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeShowCardsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeShowCardsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
