import Vue from 'vue';
import {Bar} from 'vue-chartjs';
import {Radar} from 'vue-chartjs';
import {Line} from 'vue-chartjs';

/**
 * Vue ECharts
 * https://chartjs.org/docs/latest
 *
 */

Vue.component('VChartBar', Bar);
Vue.component('VChartRadar', Radar);
Vue.component('VChartLine', Line);
