<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <v-dialog
    v-model="isVisibleState"
    :persistent="persistent"
    :max-width="600"
    :fullscreen="$vuetify.breakpoint.mobile"
    :scrollable="$vuetify.breakpoint.mobile"
  >
    <v-card class="position-relative text-center">
      <v-btn
        absolute
        plain
        small
        icon
        top
        right
        :disabled="isSubmitting"
        @click="toggleVisible(false)"
      >
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
      <v-card-title class="justify-center">
        <app-title size="normal" weight="bold">{{ title }}</app-title>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-container fluid pa-0>
          <v-row>
            <v-col v-if="withPositiveButton" cols="12" :sm="withPositiveButton ? 4 : 6">
              <v-btn
                :disabled="isSubmitting"
                block
                depressed
                color="primary"
                class="primary-contrast--text mr-4"
                @click.prevent="onPositive"
                >{{ withPositiveButton }}</v-btn
              >
            </v-col>
            <v-col cols="12" :sm="withPositiveButton ? 4 : 6">
              <v-btn
                v-if="type === 'warning'"
                :disabled="isSubmitting"
                block
                text
                color="warning"
                @click.prevent="onSubmit"
                >{{ button ? button : $t('buttons.confirmDelete') }}</v-btn
              >
              <v-btn
                v-else-if="type === 'primary'"
                :disabled="isSubmitting"
                block
                depressed
                color="primary"
                class="primary-contrast--text mr-4"
                @click.prevent="onSubmit"
                >{{ button ? button : $t('buttons.save') }}</v-btn
              >
            </v-col>
            <v-col cols="12" :sm="withPositiveButton ? 4 : 6">
              <v-btn :disabled="isSubmitting" block outlined @click="toggleVisible(false)">{{
                buttonCancel ? buttonCancel : $t('buttons.cancel')
              }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppConfirmDialog',
  props: {
    name: {
      required: true,
      type: String,
      default: '',
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    button: {
      required: false,
      type: String,
      default: '',
    },
    buttonCancel: {
      required: false,
      type: String,
      default: '',
    },
    type: {
      required: false,
      type: String,
      default: 'warning',
    },
    withPositiveButton: {
      type: String,
      default: undefined,
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      required: false,
      type: Boolean,
      default: false,
    },
    persistent: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisibleState: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.toggleVisible(value);
      },
    },
  },
  watch: {
    isVisible(value) {
      if (!value) this.$emit('close');
    },
  },
  methods: {
    toggleVisible(state) {
      this.$emit('toggle-visible', {name: this.name, state});
    },
    onSubmit() {
      this.$emit('submit');
    },
    onPositive() {
      this.$emit('positive');
    },
  },
};
</script>
