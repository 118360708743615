import axios from '@/api/axios';

const getSpecializations = () => axios.get('/directory/specializations');

const getReallyAllSpecializations = () => axios.get('/directory/specializations/really_all');

const getSpecialization = (code) => axios.get(`/directory/specializations/${code}`);

const getAddresses = (query, count, from, to) =>
  axios.get(`/directory/addresses?query=${query}&count=${count}&from_bound=${from}&to_bound=${to}`);

const getOrganizations = (query, status = 'all') =>
  axios.get(`/directory/organizations?query=${query}&status=${status}`);

const getServices = () => axios.get('/directory/services');

const getSkills = (specId, typeId, blockId, testing360Id) =>
  axios.get(
    `/directory/skills?specId=${specId}&typeId=${typeId}&blockId=${blockId}${
      testing360Id ? `&testing_360_id=${testing360Id}` : ''
    }`
  );

const getTestingIndicators = (reportName, testId) =>
  axios.get(`/directory/testing_indicators?report=${reportName}${testId ? `&test=${testId}` : ''}`);

const getMaterialTypes = () => axios.get(`/directory/material_types`);

const getScales = (specCode) => axios.get(`/directory/specializations/${specCode}/scales`);

const getTargetTypes = () => axios.get('/directory/target_types');

export default {
  getSpecializations,
  getReallyAllSpecializations,
  getSpecialization,
  getAddresses,
  getOrganizations,
  getServices,
  getSkills,
  getTestingIndicators,
  getMaterialTypes,
  getScales,
  getTargetTypes,
};
