import Vue from 'vue';

export const mutationTypes = {
  updateSurveyField: '[survey] update survey filed',

  getSurveysStart: '[survey] get surveys start',
  getSurveysSuccess: '[survey] get surveys success',
  getSurveysFailure: '[survey] get surveys failure',

  getFeedStart: '[survey] get feed start',
  getFeedSuccess: '[survey] get feed success',
  getFeedFailure: '[survey] get feed failure',

  getFeedSettingsStart: '[survey] get feed settings start',
  getFeedSettingsSuccess: '[survey] get feed settings success',
  getFeedSettingsFailure: '[survey] get feed settings failure',

  getSurveyStart: '[survey] get survey item start',
  getSurveySuccess: '[survey] get survey item success',
  getSurveySuccessState: '[survey] get survey item success state',
  getSurveyFailure: '[survey] get survey item failure',

  getSurveyMailingStart: '[survey] get survey mailing start',
  getSurveyMailingSuccess: '[survey] get survey mailing success',
  getSurveyMailingFailure: '[survey] get survey mailing failure',

  getSurveyQuestionSuccessState: '[survey] get survey question success state',

  getUnattachedSurveysStart: '[survey] get unattached survey start',
  getUnattachedSurveysSuccess: '[survey] get unattached survey success',
  getUnattachedSurveysFailure: '[survey] get unattached survey failure',

  getResearchmentStart: '[survey] get researchment item start',
  getResearchmentSuccess: '[survey] get researchment item success',
  getResearchmentSurveysSuccess: '[survey] get researchment surveys success',
  setResearchmentField: '[survey] setResearchmentField',
  getResearchmentFailure: '[survey] get researchment item failure',

  getResearchmentsStart: '[survey] get researchments start',
  getResearchmentsClear: '[survey] get researchments clear',
  getResearchmentsSuccess: '[survey] get researchments success',
  getResearchmentsFailure: '[survey] get researchments failure',

  getResearchmentTemplateListStart: '[survey] get getResearchmentTemplateList start',
  getResearchmentTemplateListSuccess: '[survey] get getResearchmentTemplateList success',
  getResearchmentTemplateListFailure: '[survey] get getResearchmentTemplateList failure',

  getSurveyResultsStart: '[survey] get survey results start',
  getSurveyResultsSuccess: '[survey] get survey results success',
  getSurveyResultsFailure: '[survey] get survey results failure',

  getSurveyStatisticStart: '[survey] get survey statistic start',
  getSurveyStatisticSuccess: '[survey] get survey statistic success',
  getSurveyStatisticFailure: '[survey] get survey statistic failure',

  getCompanySurveysStatisticStart: '[survey] get company surveys statistic start',
  getCompanySurveysStatisticSuccess: '[survey] get company surveys statistic success',
  getCompanySurveysStatisticFailure: '[survey] get company surveys statistic failure',

  getRecipientsStart: '[survey] get recipients start',
  getRecipientsSuccess: '[survey] get recipients success',
  getRecipientsFailure: '[survey] get recipients failure',

  changeSurveyStart: '[survey] change start',
  changeSurveySuccess: '[survey] change success',
  changeSurveyStatus: '[survey] change status',
  changeSurveySuccessWithData: '[survey] change success with get data',
  changeSurveyFailure: '[survey] change failure',

  copySurveyStart: '[survey] copy start',
  copySurveySuccess: '[survey] copy success',
  copySurveyFailure: '[survey] copy failure',

  createResearchmentStart: '[survey] createResearchment start',
  createResearchmentSuccess: '[survey] createResearchment success',
  createResearchmentFailure: '[survey] createResearchment failure',

  saveResearchmentStart: '[survey] save Researchment start',
  saveResearchmentSuccess: '[survey] save Researchment success',
  saveResearchmentFailure: '[survey] save Researchment failure',

  assignResearchmentStart: '[survey] assign Researchment start',
  assignResearchmentSuccess: '[survey] assign Researchment success',
  assignResearchmentFailure: '[survey] assign Researchment failure',

  delResearchmentStart: '[survey] delResearchment start',
  delResearchmentSuccess: '[survey] delResearchment success',
  delResearchmentFailure: '[survey] delResearchment failure',

  createResearchmentTemplateStart: '[survey] createResearchmentTemplate start',
  createResearchmentTemplateSuccess: '[survey] createResearchmentTemplate success',
  createResearchmentTemplateFailure: '[survey] createResearchmentTemplate failure',

  sendSurveyStart: '[survey] send to participants start',
  sendSurveySuccess: '[survey] send to participants success',
  sendSurveyFailure: '[survey] send to participants failure',

  setFeedSettingsStart: '[survey] set feed settings start',
  setFeedSettingsSuccess: '[survey] set feed settings success',
  setFeedSettingsFailure: '[survey] set feed settings failure',

  getTimetableStart: '[survey] get timetable start',
  getTimetableSuccess: '[survey] get timetable success',
  getTimetableFailure: '[survey] get timetable failure',

  changeTimetableStart: '[survey] change timetable start',
  changeTimetableSuccess: '[survey] change timetable success',
  changeTimetableFailure: '[survey] change timetable failure',
};

const mutations = {
  [mutationTypes.getSurveyStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSurveySuccess](state, payload) {
    state.isLoading = false;
    state.survey = payload;
  },
  [mutationTypes.getSurveyQuestionSuccessState](state, payload) {
    state.isLoading = false;
    Vue.set(state.survey, 'survey_meta', payload);
  },
  [mutationTypes.getSurveySuccessState](state) {
    state.isLoading = false;
  },
  [mutationTypes.getSurveyFailure](state) {
    state.isLoading = false;
  },
  [mutationTypes.getSurveyMailingStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSurveyMailingSuccess](state, payload) {
    state.isLoading = false;
    state.surveyMailing = payload;
  },
  [mutationTypes.getSurveyMailingFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUnattachedSurveysStart](state) {
    state.isUnattachedSurveysLoading = true;
  },
  [mutationTypes.getUnattachedSurveysSuccess](state, payload) {
    state.isUnattachedSurveysLoading = false;
    state.unattachedSurveys = payload;
  },
  [mutationTypes.getUnattachedSurveysFailure](state) {
    state.isUnattachedSurveysLoading = false;
  },

  [mutationTypes.updateSurveyField](state, payload) {
    Vue.set(state.survey, payload.key, payload.value);
  },

  [mutationTypes.getSurveysStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSurveysSuccess](state, payload) {
    state.isLoading = false;
    if (payload.offset === 0) {
      state.surveys = payload.items;
    } else {
      state.surveys.push(...payload.items);
    }
    state.surveysCount = payload.count;
  },
  [mutationTypes.getSurveysFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getResearchmentStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getResearchmentSuccess](state, payload) {
    state.isLoading = false;
    state.researchment = payload;
  },
  [mutationTypes.getResearchmentSurveysSuccess](state, payload) {
    state.isLoading = false;
    state.researchmentSurveys = payload;
  },
  [mutationTypes.setResearchmentField](state, {key, value}) {
    Vue.set(state.researchment, key, value);
  },
  [mutationTypes.getResearchmentFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getResearchmentsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getResearchmentsClear](state) {
    state.researchments = [];
  },
  [mutationTypes.getResearchmentsSuccess](state, payload) {
    state.isLoading = false;
    state.researchments = payload;
    state.researchmentCount = (payload || []).length;
  },
  [mutationTypes.getResearchmentsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getResearchmentTemplateListStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getResearchmentTemplateListSuccess](state, payload) {
    state.isLoading = false;
    state.researchmentTemplates = payload;
  },
  [mutationTypes.getResearchmentTemplateListFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getFeedStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getFeedSuccess](state, payload) {
    state.isLoading = false;
    state.feed = payload;
  },
  [mutationTypes.getFeedFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getFeedSettingsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getFeedSettingsSuccess](state, payload) {
    state.isLoading = false;
    state.feedSettings = payload;
  },
  [mutationTypes.getFeedSettingsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSurveyResultsStart](state) {
    state.surveyWithResults = {};
    state.isLoading = true;
  },
  [mutationTypes.getSurveyResultsSuccess](state, payload) {
    state.isLoading = false;
    state.surveyWithResults = payload;
  },
  [mutationTypes.getSurveyResultsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSurveyStatisticStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSurveyStatisticSuccess](state, payload) {
    state.isLoading = false;
    state.statistic = payload;
  },
  [mutationTypes.getSurveyStatisticFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCompanySurveysStatisticStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCompanySurveysStatisticSuccess](state, {type, payload}) {
    state.isLoading = false;
    state[type] = payload;
  },
  [mutationTypes.getCompanySurveysStatisticFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRecipientsStart](state) {
    state.isLoading = true;
    state.recipients = null;
  },
  [mutationTypes.getRecipientsSuccess](state, payload) {
    state.isLoading = false;
    state.recipients = payload;
  },
  [mutationTypes.getRecipientsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeSurveyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeSurveySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeSurveyStatus](state, status) {
    state.isSubmitting = false;
    Vue.set(state.survey, 'status', status);
  },
  [mutationTypes.changeSurveySuccessWithData](state, payload) {
    state.survey = payload;
    state.isSubmitting = false;
  },
  [mutationTypes.changeSurveyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.copySurveyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.copySurveySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.copySurveyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createResearchmentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createResearchmentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createResearchmentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.saveResearchmentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.saveResearchmentSuccess](state, payload) {
    state.isSubmitting = false;
    state.researchment = payload;
  },
  [mutationTypes.saveResearchmentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.assignResearchmentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.assignResearchmentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.assignResearchmentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.delResearchmentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.delResearchmentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.delResearchmentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createResearchmentTemplateStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createResearchmentTemplateSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createResearchmentTemplateFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.sendSurveyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.sendSurveySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.sendSurveyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setFeedSettingsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setFeedSettingsSuccess](state, payload) {
    state.isSubmitting = false;
    state.feedSettings = payload;
  },
  [mutationTypes.setFeedSettingsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getTimetableStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.timetable = null;
  },
  [mutationTypes.getTimetableSuccess](state, payload) {
    state.isLoading = false;
    state.timetable = payload;
  },
  [mutationTypes.getTimetableFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.changeTimetableStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTimetableSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTimetableFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
