export const mutationTypes = {
  logoutStart: '[auth] logout start',
  logoutSuccess: '[auth] logout success',
  logoutFailure: '[auth] logout failure',

  impersonateStart: '[auth] impersonate start',
  impersonateSuccess: '[auth] impersonate success',
  impersonateFailure: '[auth] impersonate failure',

  getCurrentUserStart: '[auth] get current user start',
  getCurrentUserSuccess: '[auth] get current user success',
  getCurrentUserFailure: '[auth] get current user failure',
};

const mutations = {
  [mutationTypes.logoutStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.logoutSuccess](state) {
    state.isSubmitting = false;
    state.currentUser = null;
    state.isLoggedIn = false;
  },
  [mutationTypes.logoutFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.impersonateStart](state) {
    state.isSubmitting = false;
    state.validationErrors = null;
  },
  [mutationTypes.impersonateSuccess](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.impersonateFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false;
    state.currentUser = payload;
    state.isLoggedIn = true;
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.currentUser = null;
    state.userRole = null;
  },
};

export default mutations;
