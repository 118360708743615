export const mutationTypes = {
  getStatusesStart: '[external] get task statuses start',
  getStatusesSuccess: '[external] get task statuses success',
  getStatusesFailure: '[external] get task statuses failure',
};

const mutations = {
  [mutationTypes.getStatusesStart](state) {
    state.errors = null;
  },
  [mutationTypes.getStatusesSuccess](state, payload) {
    state.statuses = payload;
  },
  [mutationTypes.getStatusesFailure](state, payload) {
    state.errors = payload;
  },
};

export default mutations;
