// eslint-disable-next-line no-undef
RedactorX.lang["ru"] = {
  accessibility: {
    "help-label": "Текстовый редактор"
  },
  placeholders: {
    figcaption: "Название (необязательно)"
  },
  popup: {
    back: "Назад",
    link: "Ссылка",
    add: "Добавить",
    image: "Изображение",
    "add-image": "Добавить изображение"
  },
  shortcuts: {
    "meta-a": "Выбрать всё",
    "meta-z": "Отменить",
    "meta-shift-z": "Повторить",
    "meta-shift-m": "Удалить формат",
    "meta-b": "Жирный",
    "meta-i": "Наклонный",
    "meta-u": "Подчеркнутый",
    "meta-h": "Надстрочный",
    "meta-l": "Подстрочный",
    "meta-k": "Link",
    "meta-alt-0": "Обычный текст",
    "meta-alt-1": "Заголовок 1",
    "meta-alt-2": "Заголовок 2",
    "meta-alt-3": "Заголовок 3",
    "meta-alt-4": "Заголовок 4",
    "meta-alt-5": "Заголовок 5",
    "meta-alt-6": "Заголовок 6",
    "meta-shift-7": "Нумерованный список",
    "meta-shift-8": "Ненумерованный список",
    "meta-indent": "Добавить отступ",
    "meta-outdent": "Убрать отступ",
    "meta-shift-backspace": "Удалить",
    "meta-shift-o": "Добавить",
    "meta-shift-d": "Дублировать",
    "meta-shift-up": "Переместить вверх",
    "meta-shift-down": "Переместить вниз"
  },
  buttons: {
    add: "Добавить",
    html: "HTML",
    format: "Формат",
    bold: "Жирный",
    italic: "Наклонный",
    deleted: "Зачеркнутый",
    link: "Ссылка",
    list: "Список",
    image: "Изображение",
    indent: "Добавить отступ",
    outdent: "Убрать отступ",
    embed: "Встроить",
    table: "Таблица",
    insert: "Вставить",
    save: "Сохранить",
    cancel: "Отменить",
    delete: "Удалить",
    duplicate: "Дублировать",
    shortcut: "Ярлыки",
    underline: "Подчеркнутый",
    undo: "Отмена",
    redo: "Повтор",
    code: "Код",
    mark: "Отметить",
    subscript: "Подстрочный",
    superscript: "Надстрочный",
    kbd: "Горячие клавиши",
    "image-settings": "Настройки изображения",
    "column-settings": "Настройки колонок"
  },
  blocks: {
    text: "Текст",
    paragraph: "Параграф",
    image: "Изображение",
    embed: "Встроенный элемент",
    line: "Линия",
    table: "Таблица",
    quote: "Цитата",
    pre: "Код",
    address: "Адрес"
  },
  format: {
    p: "Обычный текст",
    h1: "Заголовок 1",
    h2: "Заголовок 2",
    h3: "Заголовок 3",
    h4: "Заголовок 4",
    h5: "Заголовок 5",
    h6: "Заголовок 6",
    address: "Адрес",
    ul: "Ненумерованный список",
    ol: "Нумерованный список",
    dl: "Definition List"
  },
  embed: {
    embed: "Встроенный объект",
    caption: "Заголовок",
    description: "Вставьте любой embed/html код, или ссылку (только vimeo или youtube)",
    "responsive-video": "Адаптивное видео"
  },
  image: {
    or: "или",
    "alt-text": "Альтернативный текст",
    link: "Ссылка",
    width: "Ширина",
    caption: "Заголовок",
    "link-in-new-tab": "Открывать ссылку в новой вкладке",
    "url-placeholder": "Вставьте ссылку или изображение...",
    "upload-new-placeholder": "Перетащите изображение сюда,<br>или кликните для выбора"
  },
  link: {
    link: "Ссылка",
    "edit-link": "Редактировать ссылку",
    unlink: "Удалить ссылку",
    "link-in-new-tab": "Открывать ссылку в новой вкладке",
    text: "Текст",
    url: "URL"
  },
  table: {
    width: "Ширина",
    nowrap: "Не переносить",
    column: "Столбец",
    "add-head": "Добавить заголовок",
    "remove-head": "Удалить заголовок",
    "add-row-below": "Добавить строку ниже",
    "add-row-above": "Добавить строку выше",
    "remove-row": "Удалить строку",
    "add-column-after": "Добавить столбец справа",
    "add-column-before": "Добавить столбец слева",
    "remove-column": "Удалить столбец",
    "delete-table": "Удалить таблицу"
  },
  filelink: {
    file: "Файл",
    upload: "Загрузить",
    title: "Название",
    choose: "Выбрать",
    placeholder: "Перетащите файл сюда,<br>или кликните для выбора"
  },
  handle: {
    handle: "Упомянуть"
  },
  inlineformat: {
    "inline-format": "Формат теста",
    underline: "Подчеркнутый",
    superscript: "Надстрочный",
    subscript: "Подстрочный",
    mark: "Выделенный",
    code: "Код",
    shortcut: "Ярлык",
    "remove-format": "Удалить формат"
  },
  removeformat: {
    removeformat: "Удалить формат"
  },
  alignment: {
    alignment: "Выравнивание"
  },
  fontcolor: {
    fontcolor: "Цвет текста",
    text: "Текст",
    highlight: "Выделение",
    "remove-format": "Удалить цвет"
  }
};
