export const gettersTypes = {
  id: '[team] id',
  name: '[team] name',
  companyId: '[team] companyId',
  companyName: '[team] companyName',
  leaderId: '[team] leaderId',
  isOrganizer: '[team] isOrganizer',
  isLeader: '[team] isLeader',
  isSupervisor: '[team] isSupervisor',
  isManager: '[team] isManager',
  canManage: '[team] can manage',
};

const getters = {
  [gettersTypes.id]: (state) => {
    if (!state.metadata) return 0;
    return state.metadata.id;
  },
  [gettersTypes.name]: (state) => {
    if (!state.metadata) return null;
    return state.metadata.name;
  },
  [gettersTypes.companyId]: (state) => {
    if (!state.company) return 0;
    return state.company.id;
  },
  [gettersTypes.companyName]: (state) => {
    if (!state.company) return null;
    return state.company.name;
  },
  [gettersTypes.leaderId]: (state) => {
    if (!state.metadata) return 0;
    return state.metadata.lead_id;
  },
  [gettersTypes.isOrganizer]: (state, getters, rootState) => {
    if (!state.company) return false;
    return (
      state.company.user_id === rootState.auth.currentUser.user_id || state.company.is_organizer
    );
  },
  [gettersTypes.isLeader]: (state, getters, rootState) => {
    if (!state.metadata) return false;
    return rootState.auth.currentUser.user_id === state.metadata.lead_user_id;
  },
  [gettersTypes.isSupervisor]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_supervisor;
  },
  [gettersTypes.isManager]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_manager;
  },
  [gettersTypes.canManage]: (state, getters) => {
    return getters[gettersTypes.isLeader] || getters[gettersTypes.isSupervisor];
  },
};

export default getters;
