export const mutationTypes = {
  getAvailabilityStart: '[interconnectivity] get availability start',
  getAvailabilitySuccess: '[interconnectivity] get availability success',
  getAvailabilityFailure: '[interconnectivity] get availability failure',
};

const mutations = {
  [mutationTypes.getAvailabilityStart](state) {
    state.isLoading = true;
    state.errors = null;
    state.isAvailable = false;
  },
  [mutationTypes.getAvailabilitySuccess](state, payload) {
    state.isLoading = false;
    state.isAvailable = payload;
  },
  [mutationTypes.getAvailabilityFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

export default mutations;
