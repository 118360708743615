import axios from 'axios';

// methodologies
const getMethodologies = (companyId) => axios.get(`/feedback/${companyId}/methodologies`);

const getMethodology = (companyId, methodologyId) =>
  axios.get(`/feedback/${companyId}/methodologies/${methodologyId}`);

const createMethodology = (companyId, data) =>
  axios.post(`/feedback/${companyId}/methodologies`, data);

const changeMethodology = (companyId, methodologyId, data) =>
  axios.put(`/feedback/${companyId}/methodologies/${methodologyId}`, data);

const copyMethodology = (
  companyId,
  methodologyId,
  toCompany = undefined,
  toCompanies = undefined
) =>
  axios.post(
    `/feedback/${companyId}/methodologies/${methodologyId}/copy${
      toCompany ? `?to_company=${toCompany}` : ''
    }${toCompanies ? `?to_companies=${toCompanies}` : ''}`
  );

const exportMethodology = (companyId, methodologyId) =>
  axios.post(`/feedback/${companyId}/methodologies/${methodologyId}/export`);

const importMethodology = (companyId, data) =>
  axios.post(`/feedback/${companyId}/methodologies/import`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const deleteMethodology = (companyId, methodologyId) =>
  axios.delete(`/feedback/${companyId}/methodologies/${methodologyId}`);

// surveys

// feedbacks
const getFeedbacks = (companyId) => axios.get(`/feedback/${companyId}/feedbacks`);

const getUserFeedbacks = (companyId, userId) =>
  axios.get(`/feedback/${companyId}/user/feedbacks/statistic${userId ? `?user_id=${userId}` : ''}`);

const getFeedback = (companyId, feedbackId) =>
  axios.get(`/feedback/${companyId}/feedbacks/${feedbackId}`);

const createFeedback = (companyId, data, check) =>
  axios.post(`/feedback/${companyId}/feedbacks${check ? `?check=${check}` : ''}`, data);

const changeFeedback = (companyId, feedbackId, data) =>
  axios.put(`/feedback/${companyId}/feedbacks/${feedbackId}`, data);

const copyFeedback = (companyId, feedbackId, toCompany = undefined, toCompanies = undefined) =>
  axios.post(
    `/feedback/${companyId}/feedbacks/${feedbackId}/copy${
      toCompany ? `?to_company=${toCompany}` : ''
    }${toCompanies ? `?to_companies=${toCompanies}` : ''}`
  );

const deleteFeedback = (companyId, feedbackId) =>
  axios.delete(`/feedback/${companyId}/feedbacks/${feedbackId}`);

// report
const getStatistics = (companyId, feedbackId, {cycle, userId}) => {
  const params = {};
  if (cycle) params.cycle = cycle;
  if (userId) params.userId = userId;
  return axios.get(`/feedback/${companyId}/feedbacks/${feedbackId}/statistic`, {params});
};

const getResults = (companyId, feedbackId, cycle, teamId, userId) => {
  let params = [];
  let query = '';

  if (cycle) params.push(`cycle=${cycle}`);
  if (teamId) params.push(`team_id=${teamId}`);
  if (userId) params.push(`user_id=${userId}`);

  if (params.length) query = `?${params.join('&')}`;

  return axios.get(`/feedback/${companyId}/feedbacks/${feedbackId}/results${query}`);
};

const getCycles = (companyId, feedbackId) => {
  return axios.get(`/feedback/${companyId}/feedbacks/${feedbackId}/cycles`);
};

export default {
  getMethodologies,
  getMethodology,
  createMethodology,
  changeMethodology,
  copyMethodology,
  exportMethodology,
  importMethodology,
  deleteMethodology,

  getFeedbacks,
  getUserFeedbacks,
  getFeedback,
  createFeedback,
  changeFeedback,
  copyFeedback,
  deleteFeedback,

  getStatistics,
  getResults,
  getCycles,
};
