<template>
  <v-row class="item-hint" no-gutters>
    <span class="blue--text app-text-family-sans app-text-size--extra-small">
      <v-icon color="blue" size="16" class="item-hint__info-icon mr-1">
        mdi-information-outline
      </v-icon>
      {{ title }}
    </span>
  </v-row>
</template>

<script>
export default {
  name: 'AppItemHint',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item-hint {
  &__info-icon {
    margin-bottom: 2px;
  }
}
</style>
