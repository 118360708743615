export const mutationTypes = {
  getSEOParamsStart: '[settings] get SEO params start',
  getSEOParamsSuccess: '[settings] get SEO params success',
  getSEOParamsFailure: '[settings] get SEO params failure',

  getInstanceSettingsStart: '[settings] get instance start',
  getInstanceSettingsSuccess: '[settings] get instance success',
  getInstanceSettingsFailure: '[settings] get instance failure',

  getWebsocketUrlStart: '[settings] get websocket url start',
  getWebsocketUrlSuccess: '[settings] get websocket url success',
  getWebsocketUrlFailure: '[settings] get websocket url failure',
};

const mutations = {
  [mutationTypes.getSEOParamsStart]() {},
  [mutationTypes.getSEOParamsSuccess](state, payload) {
    state.SEOParams = payload;
  },
  [mutationTypes.getSEOParamsFailure]() {},

  [mutationTypes.getInstanceSettingsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getInstanceSettingsSuccess](state, payload) {
    state.isLoading = false;
    state.instance = payload;
  },
  [mutationTypes.getInstanceSettingsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getWebsocketUrlStart]() {},
  [mutationTypes.getWebsocketUrlSuccess](state, payload) {
    state.websocketUrl = payload;
  },
  [mutationTypes.getWebsocketUrlFailure]() {},
};

export default mutations;
