import directoryApi from '@/api/directory';
import {mutationTypes} from '@/store/modules/directory/mutations';

export const actionsTypes = {
  getSpecializations: '[directory] get specializations',
  getReallyAllSpecializations: '[directory] get really all specializations',
  getSpecialization: '[directory] get specialization',
  getAddresses: '[directory] get addresses',
  getOrganizations: '[directory] get organizations',
  getServices: '[directory] get services',
  getSkills: '[directory] get skills',
  getTestingIndicators: '[directory] get testing indicators',
  getMaterialTypes: '[directory] get material types',
  getScales: '[directory] get scales',
  getTargetTypes: '[directory] get target types',
};

const actions = {
  [actionsTypes.getSpecializations](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationsStart);
      directoryApi
        .getSpecializations()
        .then((response) => {
          context.commit(mutationTypes.getSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getReallyAllSpecializations](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReallyAllSpecializationsStart);
      directoryApi
        .getReallyAllSpecializations()
        .then((response) => {
          context.commit(mutationTypes.getReallyAllSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReallyAllSpecializationsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.getSpecialization](context, code) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationStart);
      directoryApi
        .getSpecialization(code)
        .then((response) => {
          context.commit(mutationTypes.getSpecializationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getAddresses](context, {query, count, from, to}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAddressesStart);
      directoryApi
        .getAddresses(query, count, from, to)
        .then((response) => {
          context.commit(mutationTypes.getAddressesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAddressesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getOrganizations](context, {query, status}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getOrganizationsStart);
      directoryApi
        .getOrganizations(query, status)
        .then((response) => {
          context.commit(mutationTypes.getOrganizationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getOrganizationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getServices](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getServicesStart);
      directoryApi
        .getServices()
        .then((response) => {
          context.commit(mutationTypes.getServicesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getServicesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSkills](context, {specId, typeId, blockId, testing360Id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsStart);
      directoryApi
        .getSkills(specId, typeId, blockId, testing360Id)
        .then((response) => {
          context.commit(mutationTypes.getSkillsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTestingIndicators](context, {reportName, testId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTestingIndicatorsStart);
      directoryApi
        .getTestingIndicators(reportName, testId)
        .then((response) => {
          context.commit(mutationTypes.getTestingIndicatorsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTestingIndicatorsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMaterialTypes](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMaterialTypesStart);
      directoryApi
        .getMaterialTypes()
        .then((response) => {
          context.commit(mutationTypes.getMaterialTypesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMaterialTypesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getScales](context, specCode) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getScalesStart);
      directoryApi
        .getScales(specCode)
        .then((response) => {
          context.commit(mutationTypes.getScalesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getScalesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTargetTypes](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTargetTypesStart);
      directoryApi
        .getTargetTypes()
        .then((response) => {
          context.commit(mutationTypes.getTargetTypesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTargetTypesFailure);
          reject(result);
        });
    });
  },
};

export default actions;
